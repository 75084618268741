
export const isEmptyString = (input) => {
  return input !== undefined && typeof input === 'string' && input.trim().length === 0;
};

export const isEmptyObject = (object) => {
  var isEmpty=true;
  if(!!object){
  Object.values(object).forEach(element => {
    isEmpty = isEmpty ? !element : false;
  });
  }
  return isEmpty;
}

export const isString = (input) => {
  if(!input) return false;
  return typeof input === 'string' || input instanceof String;
};

export const randomString = (length) => {
  return Math.random().toString(36).substring(length);
};

export const generateUniqueId = () => {
  let result = "";
  const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 32; i++)
    result += possible.charAt(Math.floor(Math.random() * possible.length));
  return result;
};

export const isPasswordValid = (input) => {
  const regex = /^.*(?=.{8,})((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])).*$/;
  const regexSpace = /^$|\s/
  return regex.test(input) && !regexSpace.test(input);
};

export const isTunIPValid = (input) => {
  const regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])\.){2}0.0$/;
  return regex.test(input);
};

export const isIPValid = (input) => {
  const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return regex.test(input);
};

export const isAESKeyValid = (input) => {
  const regex = /^[a-z0-9A-Z]*$/;
  return regex.test(input);
};

export const isLDAPValid = (input) => {
  const regex = /^ldap:\/\/.*$/;
  return regex.test(input);
};

export const isGroupValid = (input) => {
  const regex = /^[a-z0-9A-Z-_]+$/;
  return regex.test(input);
};

export const isURLValid = (input) => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)?/gi;
  return regex.test(input);
};

export const isFullURLValid = (input) => {
    const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)?/giy;
    const regexFull = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{1,6}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{1,6}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{1,6}|www\.[a-zA-Z0-9]+\.[^\s]{1,6})/
    return regex.test(input) || regexFull.test(input);
};

export const isSRTValid = (input) => {
  //const regex = /^srt:\/\/(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])):[0-9]{4,5}(\/[a-zA-Z0-9()@:%_+.~#?&/=-]*){0,1})|([a-zA-Z()@:%_+.~#?&/=-][a-zA-Z0-9()@:%_+.~#?&/=-]*))$/;
    const regex = /^srt:\/\/.*$/;
    return regex.test(input);
  };

export const isRTMPValid = (input) => {
  //const regex = /^rtmps?:\/\/(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])):[0-9]{4,5}(\/[a-zA-Z0-9()@:%_+.~#?&/=-]*){0,1})|([a-zA-Z()@:%_+.~#?&/=-][a-zA-Z0-9()@:%_+.~#?&/=-]*))$/;
  const regex = /^rtmps?:\/\/.*$/;
return regex.test(input);
};

export const isRTSPValid = (input) => {
    //const regex = /^rtsps?:\/\/(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])):[0-9]{4,5}(\/[a-zA-Z0-9()@:%_+.~#?&/=-]*){0,1})|([a-zA-Z()@:%_+.~#?&/=-][a-zA-Z0-9()@:%_+.~#?&/=-]*))$/;
    const regex = /^rtsps?:\/\/.*$/;
    return regex.test(input);
    };

export const isFTPRuleValid = (input) => {
  // eslint-disable-next-line
  const regex = /^((\*\.\*)?|(([^\* ]+\*|\*[^\*\. ]+\*|\*[^\* ]*[^\* .]+|[^\* ]+\*[^\* ]*[^\* .]+)[ ]?)*)$/;
  return regex.test(input);
};

export const isFTPNameValid = (input) => {
  const regex = /^[a-zA-Z0-9- _]*$/;
  return regex.test(input);
};

export const isEncoderNameValid = (input) => {
  const regex = /^[a-zA-Z0-9-_]*$/;
  return regex.test(input);
};

export const containsUnderscore = (input) => {
  const regex = /^.*_.*$/;
  return regex.test(input);
};

export const isStreamNameValid = (input) => {
  const regex = /^[a-zA-Z0-9-_\-@.]*$/;
  return regex.test(input);
};

export const isHostnameValid = (input) => {
  const regex = /^[a-zA-Z0-9-_.]*$/;
  return regex.test(input);
};