import PropTypes from "prop-types";
import React, { useRef, Fragment } from "react";
import { connect } from 'react-redux';
import { Button } from "reactstrap";
import { DropTarget } from "react-dnd";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import { DragSource } from 'react-dnd';
import Thumbnail from '../../../../common/thumbnail';
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";

import { encoderProfilePropTypes, encoderPropTypes } from "../../../../../utils/models-prop-types";
import {
  USER_ROLE_ADMIN,
  USER_ROLE_VIEWER,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  DND_ITEM_TYPE_INPUT,
  DND_ITEM_TYPE_MULTIVIEW,
  DND_ITEM_TYPE_ENCODER,
  STATUS_LIVE,
  STATUS_OFF, STATUS_ON,
  NDI,
  INPUT_ID_MULTIVIEW,
  MULTIVIEW,
  STATUS_ERROR,
  RECORD_STATUS_OFF,
  RECORD_STATUS_ON,
  RECORD_STATUS_STARTING,
  RECORD_STATUS_CODEC_NOT_SUPPORTED,
  STATUS_WARNING,
  USER_ROLE_USER,
} from "../../../../../constants";
import StatusButton from "../../../../common/status-button";
import AudioLevels from "../../../../common/audio-levels";
import Graph from "./graph";
import InputSelector from "../../../../common/input-selector";
import { AWControlGridItem, AWControlGridItemStatus, AWControlGridItemContent, AWControlGridItemContentMain,
  AWControlGridItemContentMainCompact, AWControlGridItemContentMainDetails, AWControlGridItemHeader,
  AWControlGridItemHeaderControls, AWControlGridItemHeaderMetadata, AWControlGridItemThumbnail,
  AWControlGridItemContentTitle, AWControlGridItemActions } from "@aviwest/ui-kit/dist/js/components/control-grid";
import Badge from 'reactstrap/lib/Badge';
import AWPopup, { AWPopupRow, AWPopupCol } from '@aviwest/ui-kit/dist/js/components/popup';
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { formatEncoderId } from "../../../../../utils/global-utils";
import { Translate, withLocalize } from "react-localize-redux";

const propTypes = {
  hasPrivilege: PropTypes.bool,
  hasViewerLevel: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
  encoder: encoderPropTypes,
  encoderProfiles: PropTypes.arrayOf(encoderProfilePropTypes),
  isOver: PropTypes.bool.isRequired,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_GRID]).isRequired,
  onEditSettings: PropTypes.func,
  onProfileChange: PropTypes.func.isRequired,
  onSwitchInput: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  selectedInputId: PropTypes.string,
  onHoverInput: PropTypes.func,
  onHoverEncoder: PropTypes.func,
  encoderRecordIsAllowed: PropTypes.bool,
  onRecordStart: PropTypes.func.isRequired,
  onRecordStop: PropTypes.func.isRequired,
  isBeOnAir: PropTypes.bool,

};

const dndInputSource = {
  beginDrag(props) {
    return {
      id: props.encoder.id,
      videoReturnMode: props.encoder.videoReturnMode
    };
  }
};

const dndDropTarget = {

  canDrop(props, monitor) {
    // Do not drop NDI Input to Encoder of type passthrough
    const profile = props.encoderProfiles.find(currentProfile => currentProfile.id === props.encoder.profileId);
    const item = monitor.getItem();
    return (item.channelType === NDI || monitor.getItemType() === DND_ITEM_TYPE_MULTIVIEW) && profile && (profile.encoderVideoType === 'Passthrough' || profile.encoderAudioType === 'Passthrough') ? false : true;
  },


  drop(props, monitor) {
    const itemType = monitor.getItemType();
    if (itemType) {
      const item = monitor.getItem();
      if (itemType === DND_ITEM_TYPE_INPUT || itemType === DND_ITEM_TYPE_MULTIVIEW) {
        props.onSwitchInput(props.encoder, item.id, true);
      }
    }
  }
};

const dndCollect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
};

const dndCollectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
};

const settingsMenu = (finalName, profile, props, status, handleStatusButtonChange) => {
  return (
    (props.layout === DASHBOARD_LAYOUT_INLINE && (status === STATUS_OFF || status === STATUS_ON)) ?
      <Fragment>
        {status !== STATUS_ON && status !== STATUS_LIVE &&
          <Button id={"dashboard_encoder_"+props.encoder.id+"_ejectIpProfileButton"}
                  className="basic icon"
                  disabled={!props.hasPrivilege}
                  onClick={() => props.onProfileChange(props.encoder, null)}
                  title={props.translate('genericLabel.EJECT_ENCODER_PROFILE.text')}>
            <AWIcon name="eject" />
          </Button>}
        {profile != null && <StatusButton onChange={handleStatusButtonChange} id={"dashboard_encoder_"+props.encoder.id}
              status={status}
              disabled={props.hasViewerLevel || (props.configEncoderEnable && status === STATUS_OFF) || (!props.configEncoderEnable && status !== STATUS_OFF)}
              active={status !== STATUS_OFF}/>}
        {profile != null &&
          <Button id={"dashboard_encoder_"+props.encoder.id+"_settingsButton"} 
            className="basic icon" onClick={() => props.onEditSettings(props.encoder.id)} disabled={!props.hasPrivilege}
            title={props.translate('genericLabel.SETTINGS.text')}>
            <AWIcon name="options"/>
          </Button>}
      </Fragment>
    :
      <AWPopup renderTrigger={({ ref, onClick, className, opened }) => (
        <button id={"dashboard_encoder_"+props.encoder.id+"_menuSettingButton"} ref={ref} className={className + " circular btn btn-secondary"} onClick={onClick} >
            <AWIcon name={opened ? "cancel" : "icon_menu_alt"} />
        </button>
      )}>
        {({close}) => (
          <div>
            <Badge>{finalName}</Badge>
            <AWPopupRow>
              { profile != null && <AWPopupCol>
                <Button id={"dashboard_encoder_"+props.encoder.id+"_SettingsButton"} onClick={() => props.onEditSettings(props.encoder.id)} disabled={!props.hasPrivilege || (props.isBeOnAir && profile.encoderPresetIndex !== '0') }
                  title={props.translate('genericLabel.SETTINGS.text')}>
                  <AWIcon name="options"/>
                  <span><Translate id="genericLabel.SETTINGS.text" /></span>
                </Button>
              </AWPopupCol>}
              { status !== STATUS_ON && status !== STATUS_LIVE &&
                <AWPopupCol>
                  <Button id={"dashboard_encoder_"+props.encoder.id+"_ejectIpProfileButton"}
                          onClick={() => {props.onProfileChange(props.encoder, null); close();}}
                          disabled={!props.hasPrivilege} 
                          title={props.translate('genericLabel.EJECT_ENCODER_PROFILE.text')}>
                      <AWIcon name="eject" />
                      <span><Translate id="genericLabel.EJECT_ENCODER_PROFILE.text" /></span>
                  </Button>
                </AWPopupCol>
              }
            </AWPopupRow>
          </div>
        )}
      </AWPopup>
  )
}

const Encoder = (props) => {
  const { canDrop, isOver, encoder, encoderProfiles, connectDragSource, isDragging, onHoverInput, onHoverEncoder, selectedInputId, configEncoderEnable, hasViewerLevel, onRecordStart, onRecordStop } = props;
  const { id, name, status, profileId, message, recordStatus} = encoder;
  const profile = encoderProfiles.find(currentProfile => currentProfile.id === profileId);
  const finalName = profile != null ? profile.name : name;
  const protocol = profile ? `${profile.encoderVideoType}-${profile.encoderAudioType}` : 'ENC';
  let collapsed = status === STATUS_OFF || status === STATUS_ON;
  let errorMessage = (status === STATUS_ERROR) ? <Translate id={`messageInfo.${message}.text`} /> : null;
  let warningMessage = (status === STATUS_WARNING) ? <Translate id={`messageInfo.${message}.text`} /> : null; 
  const handleStatusButtonChange = (statusRequested) => {
    if (statusRequested === STATUS_ON) {
      props.onStart(encoder);
    }
    else {
      props.onStop(encoder);
    }
  };

  const elementRef = useRef(null);
  props.connectDropTarget(elementRef);
  connectDragSource(elementRef)

  const matchSelectedInput = (encoder.inputId === INPUT_ID_MULTIVIEW && selectedInputId === MULTIVIEW) || encoder.inputId === selectedInputId;

  return (
      <AWControlGridItem collapsed={collapsed}
          ref={elementRef}
          available={status === STATUS_OFF}
          className={`aw-encoder ${isOver ? (canDrop ? 'aw-target-valid' : 'aw-target-invalid') : ''}`}
          dragged={isDragging}
          highlighted={props.isHighlighted || matchSelectedInput}
          inError={status === STATUS_ERROR}
          inWarning={status === STATUS_WARNING}
          id={`dashboard_encoder_${props.encoder.id}`}>
        <AWControlGridItemHeader>
          <AWControlGridItemStatus>
            {status === STATUS_LIVE && <Badge color='danger'>LIVE</Badge>}
            {recordStatus === RECORD_STATUS_ON && <AWBadgeLabel fill color='red'><AWIcon name="record" />REC</AWBadgeLabel>}
          </AWControlGridItemStatus>
          <AWControlGridItemHeaderMetadata align="right">
          {profile != null &&
            <div className="source">
              <span className="type protocol">{protocol}</span>
              <AWIcon name="encoder_2" />
            </div>
          }
          </AWControlGridItemHeaderMetadata>
          <AWControlGridItemHeaderControls>
          {settingsMenu(finalName, profile, props, status, handleStatusButtonChange)}
          </AWControlGridItemHeaderControls>
        </AWControlGridItemHeader>
        <AWControlGridItemContent>
            <AWControlGridItemContentMain>
              <AWControlGridItemContentMainCompact>
              {(status === STATUS_OFF || status === STATUS_ON) && props.layout === DASHBOARD_LAYOUT_GRID &&
                <AWControlGridItemContentTitle>
                  { ((configEncoderEnable && status === STATUS_OFF) || (!configEncoderEnable && status === STATUS_ON)) &&
                    <AWLoader active={true} position="centered"/>
                  }
                  <AWBadgeLabel disabled left={formatEncoderId(encoder.id)}>
                    {finalName}
                  </AWBadgeLabel>
                </AWControlGridItemContentTitle>
              }
              {(status === STATUS_OFF || status === STATUS_ON) && props.layout === DASHBOARD_LAYOUT_INLINE &&
                <Fragment>
                  {formatEncoderId(encoder.id)}
                  <AWControlGridItemContentTitle>
                    { ((configEncoderEnable && status === STATUS_OFF) || (!configEncoderEnable && status === STATUS_ON)) &&
                    <AWLoader active={true} position="centered" size="sm"/>
                    }
                    {finalName}
                  </AWControlGridItemContentTitle>
                </Fragment>
              }

            {status !== STATUS_OFF && status !== STATUS_ON &&
                <AWControlGridItemThumbnail className='draggable' overlay={<div><p className={encoder.inputInfo?.length > 28 ? 'longText' : ''}>&nbsp;{encoder.inputInfo}</p><p className={encoder.outputInfo?.length > 28 ? 'longText' : ''}>{encoder.outputInfo}</p></div>} error={errorMessage} warning={warningMessage}>
                  <Thumbnail encoderId={encoder.id} uniqueId={encoder.uniqueId} timestamp={new Date().getTime()}>
                    { !configEncoderEnable &&
                    <AWLoader active={true} position="centered"/>
                    }
                    <AWControlGridItemContentTitle>
                      <AWBadgeLabel left={formatEncoderId(encoder.id)} right={profile && profile.videoReturnMode ? <AWIcon name="video_return_profile" /> : null}>
                        {finalName}
                      </AWBadgeLabel>
                    </AWControlGridItemContentTitle>
                  </Thumbnail>
                </AWControlGridItemThumbnail>
            }
                 
            <InputSelector inputId={encoder.inputId}
                passthrough={profile && (profile.encoderVideoType === 'Passthrough' || profile.encoderAudioType === 'Passthrough') ? true : false}
                includeEncoders={false}
                includeMultiView={true}
                onChange={(inputId) => props.onSwitchInput(encoder, inputId, false)}
                onHoverInput={onHoverInput}
                onHoverEncoder={onHoverEncoder}/>

            <AWControlGridItemActions>
            { status !== STATUS_OFF && props.encoderRecordIsAllowed && //Record button, present when on live and capability in enabled
            <Button id={"dashboard_encoder_"+props.encoder.id+"_recordButton"}
            className="basic icon"
            color={ recordStatus === RECORD_STATUS_ON ? 'success' : (recordStatus === RECORD_STATUS_STARTING ? 'info' : 'secondary') }
            active={ recordStatus === RECORD_STATUS_ON || recordStatus === RECORD_STATUS_STARTING}
            disabled={ hasViewerLevel || recordStatus === RECORD_STATUS_CODEC_NOT_SUPPORTED }
            onClick={ () => recordStatus === RECORD_STATUS_OFF ? onRecordStart(encoder) : onRecordStop(encoder) }>
              <AWIcon name="record"/>
            </Button>
            }
            {profile != null && <StatusButton onChange={handleStatusButtonChange} id={"dashboard_encoder_"+props.encoder.id}
                status={status}
                disabled={hasViewerLevel || (configEncoderEnable && status === STATUS_OFF) || (!configEncoderEnable && status !== STATUS_OFF) || encoder.inputId==='0'}
                active={status !== STATUS_OFF}/>
                }
            </AWControlGridItemActions>
          </AWControlGridItemContentMainCompact>
          {status === STATUS_LIVE &&
            <AWControlGridItemContentMainDetails>
                  <AudioLevels encoderId={id} />
                  <div className="graph-container">
                    <Graph encoderId={id} />
                  </div>
            </AWControlGridItemContentMainDetails>
          }
          </AWControlGridItemContentMain>
        </AWControlGridItemContent>
      </AWControlGridItem>
  )
};

Encoder.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {

  let hasPrivilege = false;
  if(state.config.auth){
    hasPrivilege = state.identity.role === USER_ROLE_ADMIN || (state.identity.role === USER_ROLE_USER && state.config.auth.user.hasPrivilege);
  }

  return {
    hasPrivilege,
    hasViewerLevel: state.identity.role === USER_ROLE_VIEWER,
    isHighlighted: state.dashboard.highlightedEncoder === ownProps.encoder.id,
    configEncoderEnable: state.config.enc ? state.config.enc[ownProps.encoder.id].enable : false,
    encoderRecordIsAllowed: state.capabilities.encoderRecordAllowed,
    isBeOnAir: state.capabilities.isBeOnAir,
  }
};

export default connect(mapStateToProps)(DropTarget([DND_ITEM_TYPE_INPUT, DND_ITEM_TYPE_MULTIVIEW], dndDropTarget, dndCollect)(DragSource(DND_ITEM_TYPE_ENCODER, dndInputSource, dndCollectSource)(withLocalize(Encoder))));