import variables from './_variables.scss';

// Errors
export const ERROR_BAD_NETWORK = 'ERROR_BAD_NETWORK';
export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';
export const ERROR_LOGIN_TOO_MANY_ATTEMPTS = 'ERROR_LOGIN_TOO_MANY_ATTEMPTS';
export const ERROR_LOGIN_WRONG_LOGIN = 'ERROR_LOGIN_WRONG_LOGIN';
export const ERROR_LOGIN_WRONG_PASSWORD = 'ERROR_LOGIN_WRONG_PASSWORD';

/*
*   20    : FTP server command
*   21    : FTP server data
*   53    : DNS resolution
*   80    : Internet access for Databridge (when access to public internet is needed)
*   443   : Access to the HTTPS web user interface. Get the public IP address of the server.
*   1935  : RTMP
*   5322  : SSH connection for secured remote access to the server (access to the system settings menu or access for AVIWEST support).
*   8181  : SNMP Web User Interface
*   8886  : SIP Server (Manager)
*   8887  : Port NodeJS
*   8888  : Access to HTTP Web User Interface
*   8893  : API RestFull
*   19350 : RTMPS
*   31000 : SNMP Service
*   32000 : SNMP Service
*   12000-12009 : FTP in passive mode
*/
export const forbiddenTCPPorts = [20, 21, 53, 80, 443, 1935, 5322, 8181, 8886, 8887, 8888, 8893, 19350, 31000, 32000, 12000, 12001, 12002, 12003, 12004, 12005, 12006, 12007, 12008, 12009];

// Responsiveness
export const RESPONSIVE_BREAKPOINT_MOBILE = 768;
export const GRAPH_MIN_WIDTH = 300;

// Themes
export const THEME_LIGHT = 'aw-theme-light';
export const THEME_DARK = 'aw-theme-dark';

// GRAPH COLORS

export const GRAPH_GRID_COLOR = variables.color_gray_05;
export const GRAPH_GRID_COLOR_DARK = variables.color_black;
export const GRAPH_LEGEND_COLOR = variables.color_gray_02;

export const GRAPH_COLORS = [
  '#ec502e',
  '#8cc7e0',
  '#2d83a6',
  '#2da432',
  '#003f5c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ffa600',
  '#2f4b7c',
  '#00876c',
  '#51a676',
  '#88c580',
  '#c2e38c',
  '#ffff9d',
  '#fdd172',
  '#f7a258',
  '#d43d51',
];

export const LANGUAGES = [
  { name: 'English', code: 'en' },
  { name: 'French', code: 'fr' },
  { name: 'Chinese', code: 'ch' },
  { name: 'Spanish', code: 'sp' },
  { name: 'Portuguese', code: 'pt' }
];

// Spinners color
export const SPINNER_DASHBOARD_COLOR = '#777777';
export const SPINNER_FTP_COLOR = '#777777';
export const SPINNER_UPLOAD_COLOR = '#FFFFFF';

// Roles
export const USER_ROLE_ADMIN = 'ADMIN';
export const USER_ROLE_USER = 'USER';
export const USER_ROLE_VIEWER = 'VIEWER';

// License
export const LICENSE_STATUS_VALID = 'VALID';
export const LICENSE_STATUS_INVALID = 'INVALID';
// Expiration warning set to 4 weeks
export const LICENSE_MS_TO_EXPIRATION = 28 * 24 * 60 * 60 * 1000;

// Constants
export const DASHBOARD_LAYOUT_GRID = 'GRID';
export const DASHBOARD_LAYOUT_INLINE = 'INLINE';
export const DASHBOARD_LAYOUT_FULLSIZE = 'FULLSIZE';

export const FILE_LAYOUT_INLINE = 'INLINE';
export const FILE_LAYOUT_ICON = 'ICON';

// DnD
export const DND_ITEM_TYPE_INPUT = 'INPUT';
export const DND_ITEM_TYPE_MULTIVIEW = 'MULTIVIEW';
export const DND_ITEM_TYPE_FILE = 'FILE';
export const DND_ITEM_TYPE_ENCODER = 'ENCODER';
export const DND_ITEM_TYPE_IP_OUTPUT = 'IP_OUTPUT';

// Video Cards Types
export const VIDEO_CARD_TYPE_DECKLINK = 'DeckLink';
export const VIDEO_CARD_TYPE_DELTACAST = 'DeltaCast';
export const VIDEO_CARD_TYPE_RIVERMAX = 'MellanoxRivermax';

// Video bitrate modes
export const VIDEO_BITRATE_MODE_CBR = 'CBR';
export const VIDEO_BITRATE_MODE_VBR = 'VBR';

// Input types
export const INPUT_TYPE_IP = 'IP';
export const INPUT_TYPE_DEVICE = 'DEVICE';
export const INPUT_TYPE_DEFAULT = 'DEFAULT';
export const INPUT_ID_MULTIVIEW = '33';

// Multiview
export const MULTIVIEW = 'multiview';

// Output types
export const OUTPUT_TYPE_HARDWARE = 'HARDWARE';
export const OUTPUT_TYPE_NDI = 'NDI';
export const OUTPUT_TYPE_IP = 'IP';

// Statuses
export const STATUS_ERROR = 'ERROR';
export const STATUS_WARNING = 'WARNING';
export const STATUS_LIVE = 'LIVE';
export const STATUS_OFF = 'OFF';
export const STATUS_ON = 'ON';
export const STATUS_LOCKED = 'LOCKED';

export const BANNER_LEVEL_INFO = 'info';
export const BANNER_LEVEL_SUCCESS = 'success';
export const BANNER_LEVEL_WARNING = 'warning';
export const BANNER_LEVEL_ERROR = 'error';

// Record statuses
export const RECORD_STATUS_OFF = 'OFF';
export const RECORD_STATUS_STARTING = 'STARTING';
export const RECORD_STATUS_ON = 'ON';
export const RECORD_STATUS_CODEC_NOT_SUPPORTED = 'CODEC_NOT_SUPPORTED';
export const RECORD_STATUS_ERROR = 'ERROR';

// Video IFB statuses
export const VIDEO_IFB_STATUS_OFF = 'OFF';
export const VIDEO_IFB_STATUS_ERROR = 'ERROR';
export const VIDEO_IFB_STATUS_ON = 'ON';
export const VIDEO_IFB_STATUS_STARTED = 'STARTED';
export const VIDEO_IFB_STATUS_WAITING = 'WAITING';

// Live statuses
export const LIVE_STATUS_OFF = 'OFF';
export const LIVE_STATUS_CONNECTING = 'CONNECTING';
export const LIVE_STATUS_ERROR = 'ERROR';
export const LIVE_STATUS_ON = 'ON';

// Internet Access Point (IAP) statuses
export const IAP_STATUS_OFF = 'OFF';
export const IAP_STATUS_ON = 'ON';

export const PLAYBACK_STATUS_OFF = 'OFF';
export const PLAYBACK_STATUS_PLAYING = 'PLAYING';
export const PLAYBACK_STATUS_PAUSED = 'PAUSED';
export const PLAYBACK_STATUS_ERROR = 'ERROR';

// File Transfer types
export const FILE_TRANSFER_TYPE_FTP = 'FTP';
export const FILE_TRANSFER_TYPE_sFTP = 'sFTP';

// File Transfer Modes
export const FILE_TRANSFER_MODE_ACTIVE = 'ACTIVE';
export const FILE_TRANSFER_MODE_PASSIVE = 'PASSIVE';

// Server log level
export const SERVER_LOG_LEVEL_INFO = 'info';
export const SERVER_LOG_LEVEL_WARNING = 'warning';
export const SERVER_LOG_LEVEL_ERROR = 'error';

// Forward statuses
export const FORWARD_STATUS_FORWARDING = 'FORWARDING';
export const FORWARD_STATUS_DONE = 'DONE';

// Intercom statuses
export const INTERCOM_STATUS_NOT_SUPPORTED = 'NOT_SUPPORTED';
export const INTERCOM_STATUS_OFF = 'OFF';
export const INTERCOM_STATUS_STARTING = 'STARTING';
export const INTERCOM_STATUS_ERROR = 'ERROR';
export const INTERCOM_STATUS_ON = 'ON';

// Intercom card type
export const INTERCOM_CARD_RAVENNA = 'Merging RAVENNA: Merging RAVENNA';

// IP Profile protocols
export const RTMP = 'RTMP';
export const HLS = 'HLS';
export const RTSP = 'RTSP';
export const TS = 'TS';
export const SRT = 'SRT';
export const FFMPEG = 'FFMPEG';
export const WEBRTC = 'WEBRTC';
export const SDI = 'SDI';
export const SST = 'SST';
export const NDI = 'NDI';

// INPUT
export const DEJITTER_BUFFER_MIN = 200;
export const DEJITTER_BUFFER_MAX = 5000;
export const DEJITTER_BUFFER_DEFAULT = 1000;
export const VIDEO_IFB_LATENCY_MIN = 200;
export const VIDEO_IFB_LATENCY_MAX = 3000;

// Battery levels
export const BATTERY_LEVEL_EMPTY = 0;
export const BATTERY_LEVEL_20 = 1;
export const BATTERY_LEVEL_40 = 2;
export const BATTERY_LEVEL_60 = 3;
export const BATTERY_LEVEL_80 = 4;
export const BATTERY_LEVEL_FULL = 5;
export const BATTERY_LEVEL_IN_CHARGE = 6;
export const BATTERY_LEVEL_ADAPTER = 7;
export const BATTERY_LEVEL_WARNING = 8;
export const BATTERY_LEVEL_CRITICAL = 9;
export const BATTERY_LEVEL_IN_ERROR = 10;

// Devices
export const DEVICE_TYPE_AIR = 'AIR';
export const DEVICE_TYPE_HE4000 = 'HE4000';
export const DEVICE_TYPE_RACK = 'RACK';
export const DEVICE_TYPE_MOJOPRO_APP = 'MoJoPro';
export const DEVICE_TYPE_MOJOPRO_LAPTOP = 'MoJoPro-Laptop';
export const DEVICE_TYPE_PRO = 'PRO';
export const DEVICE_TYPE_STUDIO = 'STUDIO';
export const DEVICE_TYPE_UNKNOWN = 'UNKNOWN';

export const DEVICE_PRODUCT_DMNGPRO = 'DMNG-PRO';

// Video encoder types
export const VIDEO_ENCODING_TYPE_H264 = 'H264';
export const VIDEO_ENCODING_TYPE_H265 = 'H265';

// Output's Ref input statuses
export const REF_INPUT_STATUS_DISABLED = 'DISABLED';
export const REF_INPUT_STATUS_ON = 'ON';
export const REF_INPUT_STATUS_OFF = 'OFF';

// Network interface
export const NETWORK_INTERFACE_TYPE_ETHERNET = 'ETHERNET';
export const NETWORK_INTERFACE_TYPE_WIFI = 'WIFI';
export const NETWORK_INTERFACE_TYPE_CELLULAR = 'CELLULAR';
export const NETWORK_INTERFACE_TYPE_GADGET = 'GADGET';
export const NETWORK_INTERFACE_TYPE_WIFI_AP = 'WIFI_AP';

// File listing types
export const FILE_TYPE_DIRECTORY = 'directory';
export const FILE_TYPE_REGULAR_FILE = 'file';

// Remote controle type
export const HTTP_WEB_REMOTE_CONTROL_HTTP = 0;
export const HTTP_WEB_REMOTE_CONTROL_HTTPS = 1;
export const HTTPS_WEB_REMOTE_CONTROL_HTTPS = 2;


// Banners Ids
export const BANNER_MESSAGE_RECONNECTION_ATTEMPT = 'BANNER_MESSAGE_RECONNECTION_ATTEMPT';
export const BANNER_MESSAGE_TIMEOUT_WARNING = 'BANNER_MESSAGE_TIMEOUT_WARNING';
export const BANNER_MESSAGE_REQUEST_AUTHENTIFICATION_KEY = 'BANNER_MESSAGE_REQUEST_AUTHENTIFICATION_KEY';
export const BANNER_MESSAGE_SERVER_ERROR = 'BANNER_MESSAGE_SERVER_ERROR';
export const BANNER_MESSAGE_SERVER_SUCCESS = 'BANNER_MESSAGE_SERVER_SUCCESS';
export const BANNER_MESSAGE_CONFIRM_GET_LOGS = 'BANNER_MESSAGE_CONFIRM_GET_LOGS';
export const BANNER_MESSAGE_CONFIRM_GET_REPORT = 'BANNER_MESSAGE_CONFIRM_GET_REPORT';
export const BANNER_MESSAGE_CONFIRM_EXPORT_CONFIG = 'BANNER_MESSAGE_CONFIRM_EXPORT_CONFIG';
export const BANNER_MESSAGE_CONFIRM_EXPORT_STREAMHUB_PROFILE = 'BANNER_MESSAGE_CONFIRM_EXPORT_STREAMHUB_PROFILE';
export const BANNER_MESSAGE_UPLOAD_IN_PROGRESS = 'BANNER_MESSAGE_UPLOAD_IN_PROGRESS'

// Settings menu
export const INPUT_SETTINGS_MENU_GENERAL = 'GENERAL';
export const INPUT_SETTINGS_MENU_IP_PROFILES = 'IP_PROFILES';
export const INPUT_SETTINGS_MENU_INTERCOM = 'INTERCOM';

// Actions constants
export const ADD_LOG = 'ADD_LOG';
export const BANNER_DISMISS_MESSAGE = 'BANNER_DISMISS_MESSAGE';
export const BANNER_OVERRIDE_MESSAGE = 'BANNER_OVERRIDE_MESSAGE';
export const BANNER_PRESENT_MESSAGE = 'BANNER_PRESENT_MESSAGE';
export const BANNER_QUEUE_MESSAGE = 'BANNER_QUEUE_MESSAGE';

export const RESPONSIVE_CHANGE = 'RESPONSIVE_MOBILE';

export const IDENTITY_CHECKED = 'IDENTITY_CHECKED';
export const LOGIN = 'LOGIN';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const RECEIVED_CONFIG = 'RECEIVED_CONFIG';
export const RECEIVED_CAPABILITIES = 'RECEIVED_CAPABILITIES';
export const RECEIVED_ORION_PLATFORM = 'RECEIVED_ORION_PLATFORM'
export const RECEIVED_CHANNEL_STATUS = 'RECEIVED_CHANNEL_STATUS';
export const RECEIVED_OUTPUT_STATUS = 'RECEIVED_OUTPUT_STATUS';
export const RECEIVED_NDI_STREAMS_CHANGE = 'RECEIVED_NDI_STREAMS_CHANGE';
export const RECEIVED_AES67_SOURCES_CHANGE = 'RECEIVED_AES67_SOURCES_CHANGE';
export const RECEIVED_LICENSE = 'RECEIVED_LICENSE';
export const EMITTING_CONFIG = 'EMITTING_CONFIG';
export const RECEIVED_LANGUAGES = 'RECEIVED_LANGUAGES';
export const RECEIVED_STATUS_DEVICES_CHANGE = 'RECEIVED_STATUS_DEVICES_CHANGE';
export const RECEIVED_LIVE_PROFILE = 'RECEIVED_LIVE_PROFILE';
export const RECEIVED_LIVE_INFO = 'RECEIVED_LIVE_INFO';
export const RECEIVED_META_DATA = 'RECEIVED_META_DATA';
export const RECEIVED_NETWORK_INTERFACES_STATUS = 'RECEIVED_NETWORK_INTERFACES_STATUS';
export const RECEIVED_NETWORK_STREAMHUB_INTERFACES_STATUS = 'RECEIVED_NETWORK_STREAMHUB_INTERFACES_STATUS';
export const RECEIVED_ENCODERS_STATUS = 'RECEIVED_ENCODERS_STATUS';
export const RECEIVED_ENCODERS_PREVIEW = 'RECEIVED_ENCODERS_PREVIEW';
export const RECEIVED_LOGS = 'RECEIVED_LOGS';
export const RECEIVED_NEW_LOG = 'RECEIVED_NEW_LOG';
export const RECEIVED_STREAMHUB_METADATA = 'RECEIVED_STREAMHUB_METADATA';
export const RECEIVED_STREAM_STATS = 'RECEIVED_STREAM_STATS';
export const RECEIVED_OUTPUT_STATS = 'RECEIVED_OUTPUT_STATS';
export const RECEIVED_USER_ROLE = 'RECEIVED_USER_ROLE';
export const RECEIVED_NEW_TOKEN = 'RECEIVED_NEW_TOKEN';
export const RECEIVED_MONITOR_INFO = 'RECEIVED_MONITOR_INFO';
export const RECEIVED_TRANSCODER_STATUS = 'RECEIVED_TRANSCODER_STATUS';
export const RECEIVED_AUDIO_DEVICES = 'RECEIVED_AUDIO_DEVICES';
export const RECEIVED_IP_LOCAL = 'RECEIVED_IP_LOCAL';
export const RECEIVED_IP_PUBLIC = 'RECEIVED_IP_PUBLIC';
export const RECEIVED_IP_SMPTE = 'RECEIVED_IP_SMPTE';
export const RECEIVED_USER_SESSIONS = 'RECEIVED_USER_SESSIONS';
export const RECEIVED_FTP_TEST = 'RECEIVED_FTP_TEST';
export const RECEIVED_INPUT_INTERCOM_INFO = 'RECEIVED_INPUT_INTERCOM_INFO';
export const RECEIVED_INPUT_INTERCOM_VOLUME = 'RECEIVED_INPUT_INTERCOM_VOLUME';
export const RECEIVED_HELP_LINK = 'RECEIVED_HELP_LINK';
export const RECEIVED_DECRYPT_PASSWORD = 'RECEIVED_DECRYPT_PASSWORD';
export const RECEIVED_REPORT_STATUS = 'RECEIVED_REPORT_STATUS';
export const SERVER_WAITING = 'SERVER_WAITING';
export const REFRESH_PAGE = 'REFRESH_PAGE';
export const RECEIVED_PTP_CLOCK_STATUS = 'RECEIVED_PTP_CLOCK_STATUS';
export const RECEIVED_NMOS_REG_STATUS = 'RECEIVED_NMOS_REG_STATUS';

export const DASHBOARD_SPLITTER_POSITION_CHANGE = 'DASHBOARD_SPLITTER_POSITION_CHANGE';
export const DASHBOARD_MOBILE_SHOW_OUTPUTS = 'DASHBOARD_MOBILE_SHOW_OUTPUTS';
export const DASHBOARD_ENCODERS_DISPLAY = 'DASHBOARD_ENCODERS_DISPLAY';

export const DASHBOARD_FILES_DISPLAY = 'DASHBOARD_FILES_DISPLAY';
export const DASHBOARD_FILES_LAYOUT_CHANGE = 'DASHBOARD_FILES_LAYOUT_CHANGE';

export const DASHBOARD_ENCODER_START = 'DASHBOARD_ENCODER_START';
export const DASHBOARD_ENCODER_STOP = 'DASHBOARD_ENCODER_STOP';
export const DASHBOARD_ENCODER_ATTACH_PROFILE = 'DASHBOARD_ENCODER_ATTACH_PROFILE';
export const DASHBOARD_ENCODER_DETACH_PROFILE = 'DASHBOARD_ENCODER_DETACH_PROFILE';
export const DASHBOARD_ENCODER_SWITCH_INPUT = 'DASHBOARD_ENCODER_SWITCH_INPUT';
export const DASHBOARD_ENCODER_SETTINGS = 'DASHBOARD_ENCODER_SETTINGS';
export const DASHBOARD_ENCODERS_LAYOUT_CHANGE = 'DASHBOARD_ENCODERS_LAYOUT_CHANGE';
export const DASHBOARD_HIGHLIGHT_ENCODER = 'DASHBOARD_HIGHLIGHT_ENCODER';

export const DASHBOARD_MULTIVIEW_START = 'DASHBOARD_MULTIVIEW_START';
export const DASHBOARD_MULTIVIEW_STOP = 'DASHBOARD_MULTIVIEW_STOP';
export const DASHBOARD_MULTIVIEW_SWITCH_AUDIO = 'DASHBOARD_MULTIVIEW_SWITCH_AUDIO';
export const DASHBOARD_MULTIVIEW_CHANGE_GRID = 'DASHBOARD_MULTIVIEW_CHANGE_GRID';
export const DASHBOARD_MULTIVIEW_SHOW_OVERLAY = 'DASHBOARD_MULTIVIEW_SHOW_OVERLAY';
export const DASHBOARD_MULTIVIEW_SETTINGS = 'DASHBOARD_MULTIVIEW_SETTINGS';
export const DASHBOARD_MULTIVIEW_MODAL = 'DASHBOARD_MULTIVIEW_MODAL';

export const DASHBOARD_IP_INPUTS_PROFILES_SETTINGS = 'DASHBOARD_IP_INPUTS_PROFILES_SETTINGS';
export const DASHBOARD_IP_OUTPUTS_PROFILES_SETTINGS = 'DASHBOARD_IP_OUTPUTS_PROFILES_SETTINGS';
export const DASHBOARD_ENCODERS_PROFILES_SETTINGS = 'DASHBOARD_ENCODERS_PROFILES_SETTINGS';

export const DASHBOARD_OUTPUT_ATTATCH_NDI_PROFILE = 'DASHBOARD_OUTPUT_ATTATCH_NDI_PROFILE';
export const DASHBOARD_OUTPUT_DETATCH_NDI_PROFILE = 'DASHBOARD_OUTPUT_DETATCH_NDI_PROFILE';
export const DASHBOARD_OUTPUT_ATTATCH_HARDWARE_PROFILE = 'DASHBOARD_OUTPUT_ATTATCH_HARDWARE_PROFILE';
export const DASHBOARD_OUTPUT_DETATCH_HARDWARE_PROFILE = 'DASHBOARD_OUTPUT_DETATCH_HARDWARE_PROFILE';

export const DASHBOARD_INPUT_ATTACH_PROFILE = 'DASHBOARD_INPUT_ATTACH_PROFILE';
export const DASHBOARD_INPUT_DETACH_PROFILE = 'DASHBOARD_INPUT_DETACH_PROFILE';
export const DASHBOARD_INPUT_DISCONNECT = 'DASHBOARD_INPUT_DISCONNECT';
export const DASHBOARD_INPUT_INTERCOM_START = 'DASHBOARD_INPUT_INTERCOM_START';
export const DASHBOARD_INPUT_INTERCOM_STOP = 'DASHBOARD_INPUT_INTERCOM_STOP';
export const DASHBOARD_INPUT_RECORD_START = 'DASHBOARD_INPUT_RECORD_START';
export const DASHBOARD_INPUT_RECORD_STOP = 'DASHBOARD_INPUT_RECORD_STOP';
export const DASHBOARD_INPUT_EJECT_VIDEO_IFB = 'DASHBOARD_INPUT_EJECT_VIDEO_IFB';
export const DASHBOARD_INPUT_REMOTE_LIVE_START = 'DASHBOARD_INPUT_REMOTE_LIVE_START';
export const DASHBOARD_INPUT_REMOTE_LIVE_STOP = 'DASHBOARD_INPUT_REMOTE_LIVE_STOP';
export const DASHBOARD_INPUT_REMOTE_FORWARD_STOP = 'DASHBOARD_INPUT_REMOTE_FORWARD_STOP';
export const DASHBOARD_INPUT_REMOTE_PROFILE_SWITCH = 'DASHBOARD_INPUT_REMOTE_PROFILE_SWITCH';
export const DASHBOARD_INTERCOM_MUTE = 'DASHBOARD_INTERCOM_MUTE';
export const DASHBOARD_INPUT_INTERCOM_AUDIO_PATTERN = 'DASHBOARD_INPUT_INTERCOM_AUDIO_PATTERN';
export const DASHBOARD_INPUT_SUBSCRIBE = 'DASHBOARD_INPUT_SUBSCRIBE';
export const DASHBOARD_INPUT_UNSUBSCRIBE = 'DASHBOARD_INPUT_UNSUBSCRIBE';
export const DASHBOARD_INPUT_SETTINGS = 'DASHBOARD_INPUT_SETTINGS';
export const DASHBOARD_INPUT_REMOTE_CONTROL = 'DASHBOARD_INPUT_REMOTE_CONTROL';
export const DASHBOARD_INPUT_REMOTE_CONTROL_TOKEN_REPLACE = 'DASHBOARD_INPUT_REMOTE_CONTROL_TOKEN_REPLACE';
export const DASHBOARD_INPUT_START = 'DASHBOARD_INPUT_START';
export const DASHBOARD_INPUT_STOP = 'DASHBOARD_INPUT_STOP';
export const DASHBOARD_INPUT_CHANGE_VIDEO_CAPPED_BITRATE = 'DASHBOARD_INPUT_CHANGE_VIDEO_CAPPED_BITRATE';
export const DASHBOARD_INPUT_CHANGE_LATENCY = 'DASHBOARD_INPUT_CHANGE_LATENCY';
export const DASHBOARD_INPUT_RESET_DROPPED_PACKETS = 'DASHBOARD_INPUT_RESET_DROPPED_PACKETS';
export const DASHBOARD_INPUT_ENTER_DETAILS = 'DASHBOARD_INPUT_ENTER_DETAILS';
export const DASHBOARD_INPUT_CLOSE_DETAILS = 'DASHBOARD_INPUT_CLOSE_DETAILS';
export const DASHBOARD_INPUTS_LAYOUT_CHANGE = 'DASHBOARD_INPUTS_LAYOUT_CHANGE';
export const DASHBOARD_INPUTS_FILTER_CHANGE = 'DASHBOARD_INPUTS_FILTER_CHANGE';
export const DASHBOARD_INPUT_PLAYBACK_START = 'DASHBOARD_INPUT_PLAYBACK_START';
export const DASHBOARD_INPUT_PLAYBACK_PAUSE = 'DASHBOARD_INPUT_PLAYBACK_PAUSE';
export const DASHBOARD_INPUT_PLAYBACK_PLAY = 'DASHBOARD_INPUT_PLAYBACK_PLAY';
export const DASHBOARD_INPUT_PLAYBACK_SEEK = 'DASHBOARD_INPUT_PLAYBACK_SEEK';
export const DASHBOARD_INPUT_PLAYBACK_STOP = 'DASHBOARD_INPUT_PLAYBACK_STOP';
export const DASHBOARD_INPUT_PLAYBACK_LOOP_ON = 'DASHBOARD_INPUT_PLAYBACK_LOOP_ON';
export const DASHBOARD_INPUT_PLAYBACK_LOOP_OFF = 'DASHBOARD_INPUT_PLAYBACK_LOOP_OFF';
export const DASHBOARD_INPUT_VIDEO_IFB_ENCODER_BIND = 'DASHBOARD_INPUT_VIDEO_IFB_ENCODER_BIND';
export const DASHBOARD_INPUT_VIDEO_IFB_SOURCE_BIND = 'DASHBOARD_INPUT_VIDEO_IFB_SOURCE_BIND';
export const DASHBOARD_HIGHLIGHT_INPUT = 'DASHBOARD_HIGHLIGHT_INPUT';
export const DASHBOARD_OUTPUT_START = 'DASHBOARD_OUTPUT_START';
export const DASHBOARD_OUTPUT_STOP = 'DASHBOARD_OUTPUT_STOP';
export const DASHBOARD_OUTPUT_LOCK = 'DASHBOARD_OUTPUT_LOCK';
export const DASHBOARD_OUTPUT_UNLOCK = 'DASHBOARD_OUTPUT_UNLOCK';
export const DASHBOARD_OUTPUT_HARDWARE_SETTINGS = 'DASHBOARD_OUTPUT_HARDWARE_SETTINGS';
export const DASHBOARD_OUTPUT_NDI_SETTINGS = 'DASHBOARD_OUTPUT_NDI_SETTINGS';
export const DASHBOARD_OUTPUT_IP_SETTINGS = 'DASHBOARD_OUTPUT_IP_SETTINGS';
export const DASHBOARD_OUTPUT_IP_PREVIEW = 'DASHBOARD_OUTPUT_IP_PREVIEW';
export const DASHBOARD_OUTPUT_SWITCH_INPUT = 'DASHBOARD_OUTPUT_SWITCH_INPUT';
export const DASHBOARD_OUTPUT_ATTACH_PROFILE = 'DASHBOARD_OUTPUT_ATTACH_PROFILE';
export const DASHBOARD_OUTPUT_DETACH_PROFILE = 'DASHBOARD_OUTPUT_DETACH_PROFILE';
export const DASHBOARD_OUTPUTS_LAYOUT_CHANGE = 'DASHBOARD_OUTPUTS_LAYOUT_CHANGE';
export const DASHBOARD_OUTPUTS_FILTER_CHANGE = 'DASHBOARD_OUTPUTS_FILTER_CHANGE';
export const DASHBOARD_OUTPUTS_CHANGE_POSITION = 'DASHBOARD_OUTPUTS_CHANGE_POSITION';
export const DASHBOARD_OUTPUTS_PERSIST_POSITIONS = 'DASHBOARD_OUTPUTS_PERSIST_POSITIONS';
export const DASHBOARD_INPUT_RECONNECT_MODEM = 'DASHBOARD_INPUT_RECONNECT_MODEM';
export const DASHBOARD_INPUT_DISCONNECT_MODEM = 'DASHBOARD_INPUT_DISCONNECT_MODEM';

export const SETTINGS_GENERAL_UPDATE = 'SETTINGS_GENERAL_UPDATE';
export const SETTINGS_GENERAL_EXPORT_CONFIG = 'SETTINGS_GENERAL_EXPORT_CONFIG';
export const SETTINGS_GENERAL_IMPORT_CONFIG_MODAL = 'SETTINGS_GENERAL_IMPORT_CONFIG_MODAL';
export const SETTINGS_GENERAL_IMPORT_CONFIG = 'SETTINGS_GENERAL_IMPORT_CONFIG';
export const SETTINGS_GENERAL_RESET_CONFIG_MODAL = 'SETTINGS_GENERAL_RESET_CONFIG_MODAL';
export const SETTINGS_GENERAL_RESET_CONFIG = 'SETTINGS_GENERAL_RESET_CONFIG';
export const SETTINGS_GENERAL_EXPORT_STREAMHUB_PROFILE = 'SETTINGS_GENERAL_EXPORT_STREAMHUB_PROFILE';
export const SETTINGS_CUSTOM_PATTERN_UPDATE = 'SETTINGS_CUSTOM_PATTERN_UPDATE';
export const SETTINGS_SECURITY_UPDATE = 'SETTINGS_SECURITY_UPDATE';
export const SETTINGS_LDAP_UPDATE = 'SETTINGS_LDAP_UPDATE';
export const SETTINGS_SECURITY_UNBLOCK_ACCOUNTS = 'SETTINGS_SECURITY_UNBLOCK_ACCOUNTS';
export const SETTINGS_NETWORK_UPDATE = 'SETTINGS_NETWORK_UPDATE';
export const SETTINGS_NDI_UPDATE = 'SETTINGS_NDI_UPDATE';
export const SETTINGS_NETWORK_ADVANCED_UPDATE = 'SETTINGS_NETWORK_ADVANCED_UPDATE';
export const SETTINGS_MEDIA_RECEIVED_FILES_UPDATE = 'SETTINGS_MEDIA_RECEIVED_FILES_UPDATE';
export const SETTINGS_MEDIA_RECORDED_FILES_UPDATE = 'SETTINGS_MEDIA_RECORDED_FILES_UPDATE';
export const SETTINGS_MEDIA_FILE_CLEANER_UPDATE = 'SETTINGS_MEDIA_FILE_CLEANER_UPDATE';
export const SETTINGS_MEDIA_RENAME_FILES_UPDATE = 'SETTINGS_MEDIA_RENAME_FILES_UPDATE';
export const SETTINGS_FILE_TRANSFER_CREATE = 'SETTINGS_FILE_TRANSFER_CREATE';
export const SETTINGS_FILE_TRANSFER_DELETE = 'SETTINGS_FILE_TRANSFER_DELETE';
export const SETTINGS_FILE_TRANSFER_TEST = 'SETTINGS_FILE_TRANSFER_TEST';
export const SETTINGS_FILE_TRANSFER_TEST_RESET = 'SETTINGS_FILE_TRANSFER_TEST_RESET';
export const SETTINGS_FILE_TRANSFER_UPDATE = 'SETTINGS_FILE_TRANSFER_UPDATE';
export const SETTINGS_MULTIVIEW_SAVE = 'SETTINGS_MULTIVIEW_SAVE';
export const SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX = 'multiview';
export const SETTINGS_INPUT_SAVE = 'SETTINGS_INPUT_SAVE';
export const SETTINGS_INPUT_IP_PROFILE_CREATE = 'SETTINGS_INPUT_IP_PROFILE_CREATE';
export const SETTINGS_INPUT_TMP_PROFILE_CREATE = 'SETTINGS_INPUT_TMP_PROFILE_CREATE';
export const SETTINGS_INPUT_TMP_PROFILE_RESULT = 'SETTINGS_INPUT_TMP_PROFILE_RESULT';
export const SETTINGS_INPUT_IP_PROFILE_DELETE = 'SETTINGS_INPUT_IP_PROFILE_DELETE';
export const SETTINGS_INPUT_IP_PROFILE_UPDATE = 'SETTINGS_INPUT_IP_PROFILE_UPDATE';
export const SETTINGS_OUTPUT_HARDWARE_SAVE = 'SETTINGS_OUTPUT_HARDWARE_SAVE';
export const SETTINGS_OUTPUT_NDI_SAVE = 'SETTINGS_OUTPUT_NDI_SAVE';
export const SETTINGS_GET_NDI_STREAMS = 'SETTINGS_GET_NDI_STREAMS';
export const SETTINGS_PREP_NDI_FINDER = 'SETTINGS_PREP_NDI_FINDER';
export const SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX = 'output';
export const SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX = 'NDIOutput';
export const SETTINGS_OUTPUT_IP_PROFILE_CREATE = 'SETTINGS_OUTPUT_IP_PROFILE_CREATE';
export const SETTINGS_OUTPUT_IP_PROFILE_DELETE = 'SETTINGS_OUTPUT_IP_PROFILE_DELETE';
export const SETTINGS_OUTPUT_IP_PROFILE_UPDATE = 'SETTINGS_OUTPUT_IP_PROFILE_UPDATE';
export const SETTINGS_INTERCOM_UPDATE = 'SETTINGS_INTERCOM_UPDATE';
export const SETTINGS_INTERCOM_INPUTS_UPDATE = 'SETTINGS_INTERCOM_INPUTS_UPDATE';
export const SETTINGS_INTERCOM_OUTPUTS_UPDATE = 'SETTINGS_INTERCOM_OUTPUTS_UPDATE';
export const SETTINGS_GET_AES67_SOURCES_LIST = 'SETTINGS_GET_AES67_SOURCES_LIST'
export const SETTINGS_VIDEOCARDS_UPDATE = 'SETTINGS_VIDEOCARDS_UPDATE';
export const SETTINGS_ENCODER_PROFILE_CREATE = 'SETTINGS_ENCODER_PROFILE_CREATE';
export const SETTINGS_ENCODER_PROFILE_UPDATE = 'SETTINGS_ENCODER_PROFILE_UPDATE';
export const SETTINGS_ENCODER_PROFILE_DELETE = 'SETTINGS_ENCODER_PROFILE_DELETE';
export const DASHBOARD_ENCODER_RECORD_START = 'DASHBOARD_ENCODER_RECORD_START';
export const DASHBOARD_ENCODER_RECORD_STOP = 'DASHBOARD_ENCODER_RECORD_STOP';

export const SETTINGS_ENCODER_CUSTOM_PATTERN_PREFIX = 'encoder';

export const TOOLS_LOGS_FILTERS_CHANGE = 'TOOLS_LOGS_FILTERS_CHANGE';
export const TOOLS_LOGS_EXPORT = 'TOOLS_LOGS_EXPORT';
export const TOOLS_LOGS_PAGE_CHANGE = 'TOOLS_LOGS_PAGE_CHANGE';

export const TOOLS_SYSTEM_RESTART_STREAMHUB_MODAL = 'TOOLS_SYSTEM_RESTART_STREAMHUB_MODAL';
export const TOOLS_SYSTEM_REBOOT_SERVER_MODAL = 'TOOLS_SYSTEM_REBOOT_SERVER_MODAL';
export const TOOLS_SYSTEM_HALT_SERVER_MODAL = 'TOOLS_SYSTEM_HALT_SERVER_MODAL';
export const TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL = 'TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL';
export const TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE_MODAL = 'TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE_MODAL';
export const TOOLS_SYSTEM_GRAPH_MODAL = 'TOOLS_SYSTEM_GRAPH_MODAL';
export const TOOLS_SYSTEM_LICENSE_MODAL = 'TOOLS_SYSTEM_LICENSE_MODAL';

export const TOOLS_SYSTEM_RESTART_STREAMHUB = 'TOOLS_SYSTEM_RESTART_STREAMHUB';
export const TOOLS_SYSTEM_REBOOT_SERVER = 'TOOLS_SYSTEM_REBOOT_SERVER';
export const TOOLS_SYSTEM_HALT_SERVER = 'TOOLS_SYSTEM_HALT_SERVER';
export const TOOLS_SYSTEM_UPLOAD_FIRMWARE_START = 'TOOLS_SYSTEM_UPLOAD_FIRMWARE_START';
export const TOOLS_SYSTEM_UPLOADING_FIRMWARE = 'TOOLS_SYSTEM_UPLOADING_FIRMWARE';
export const TOOLS_SYSTEM_UPLOAD_FIRMWARE_CANCEL = 'TOOLS_SYSTEM_UPLOAD_FIRMWARE_CANCEL';
export const TOOLS_SYSTEM_UPLOAD_FIRMWARE_RESULT = 'TOOLS_SYSTEM_UPLOAD_FIRMWARE_RESULT';
export const TOOLS_SYSTEM_UPLOAD_FIRMWARE_REBOOT = 'TOOLS_SYSTEM_UPLOAD_FIRMWARE_REBOOT';
export const TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE = 'TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE';
export const TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE_RESULT = 'TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE_RESULT';
export const TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE = 'TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE';
export const TOOLS_SYSTEM_GENERATE_REPORT = 'TOOLS_SYSTEM_GENERATE_REPORT';

export const PASSWORD_UPDATE = 'PASSWORD_UPDATE';
export const PASSWORD_SAMBA_UPDATE = 'PASSWORD_SAMBA_UPDATE';

export const LICENSE_MODAL = 'LICENSE_MODAL';
export const LICENSE_UPLOAD = 'LICENSE_UPLOAD';
export const LICENSE_UPLOAD_REPONSE = 'LICENSE_UPLOAD_REPONSE';
export const LICENSE_UPLOAD_SMPTE2110 = 'LICENSE_UPLOAD_SMPTE2110';
export const LICENSE_UPLOAD_REPONSE_SMPTE2110 = 'LICENSE_UPLOAD_REPONSE_SMPTE2110';

export const HELP_MODAL = 'HELP_MODAL';

export const LANGUAGE_MODAL = 'LANGUAGE_MODAL';

export const FILES_LAYOUT_CHANGE = 'FILES_LAYOUT_CHANGE';
export const FILES_UPLOAD_DIRECTORY_CONTENT = 'FILES_UPLOAD_DIRECTORY_CONTENT';
export const UPDATE_FILES_UPLOAD_DIRECTORY_CONTENT = 'UPDATE_FILES_UPLOAD_DIRECTORY_CONTENT';
export const RENAME_STUDIO_FILE = 'RENAME_STUDIO_FILE';
export const FILES_DELETE_FILE = 'FILES_DELETE_FILE';
export const FILES_PREVIEW_VIDEO_FILE = 'FILES_PREVIEW_VIDEO_FILE';

export const INTERCOM_OPEN = 'INTERCOM_OPEN';
export const DECRYPT_PASSWORD = 'DECRYPT_PASSWORD'

// Local Storage keys
export const LS_DEBUG = 'debug';
export const LS_THEME = 'theme';
export const LS_KEY_IDENTITY = 'identity';
export const LS_DASHBOARD_VERTICAL_SIZES = 'dashboardVerticalSizes';
export const LS_DASHBOARD_HORIZONTAL_SIZES = 'dashboardHorizontalSizes';
export const LS_DASHBOARD_INPUTS_LAYOUT = 'dashboardInputsLayout';
export const LS_DASHBOARD_INPUTS_FILTERS = 'dashboardInputsFilters';
export const LS_DASHBOARD_OUTPUTS_LAYOUT = 'dashboardOutputsLayout';
export const LS_DASHBOARD_OUTPUTS_FILTERS = 'dashboardOutputsFilters';

// HTTP Codes
export const HTTP_CODE_FORBIDDEN = 403;
export const HTTP_CODE_LOCKED = 423;
export const HTTP_CODE_BAD_GATEWAY = 502;

export const TIMEOUT_RESET = 'TIMEOUT_RESET'

// Socket events (SR => Socket received, SE => Socket emit)
export const SR_CONNECT = 'connect';
export const SR_CONNECT_ERROR = 'connect_error';

export const SR_ABUS_PROXY_READY = 'abusProxyIsReady';
export const SR_DEVICE_INFO = 'getDeviceInfo';
export const SR_CAPABILITIES = 'getCapabilities';
export const SR_CONFIG = 'getConfig';
export const SR_ERROR = 'error';
export const SR_IP_LOCAL = 'ip_local';
export const SR_IP_PUBLIC = 'ip_public';
export const SR_IP_SMPTE = 'ip_smpte2110';
export const SR_LICENSE = 'getLicense';
export const SR_ORION_PLATFORM = 'getOrionPlatform';
export const SR_LIVE_INFO = 'getLiveInfo';
export const SR_META_DATA = 'metaDataChangeEvent';
export const SR_LIVE_PROFILE = 'liveProfile';
export const SR_LOGS = 'coremngLogs';
export const SR_NEW_LOG = 'coremngNewLogEvent';
export const SR_LOG_FILE = 'getLogFile';
export const SR_CHANNEL_STATUS = 'getChannelStatus';
export const SR_CHANNELS_INFO = 'getChannelsInfo';
export const SR_CHANNELS_VOLUME = 'getChannelsVolume';
export const SR_OUTPUT_STATUS = 'getOutputStatus';
export const SR_OUTPUT_STATS = 'getOutputStats';
export const SR_PREVIEW_CHANGE = 'previewChange';
export const SR_INPUT_PREVIEW_CHANGE = 'inputPreviewChange';
export const SR_HARDWARE_OUTPUT_PREVIEW_CHANGE = 'physicalPreviewChange';
export const SR_NDI_OUTPUT_PREVIEW_CHANGE = 'NDIPreviewChange';
export const SR_NDI_STREAMS_CHANGE = 'NDIStreamsChange';
export const SR_MOSAIC_PREVIEW_CHANGE = 'mosaicPreviewChange';
export const SR_AUDIO_DEVICES = 'getAudioDevices';
export const SR_MONITOR_INFO = 'getMonitorInfo';
export const SR_TRANSCODER_STATUS = 'getTranscoderStatus';
export const SR_RECONNECT_ATTEMPT = 'reconnect_attempt';
export const SR_RECONNECT_FAILED = 'reconnect_failed';
export const SR_SERVER_ERROR = 'serverError';
export const SR_SERVER_SUCCESS = 'serverSuccess';
export const SR_STATUS_DEVICES_CHANGE = 'getStatusDevicesChange';
export const SR_INPUTS_STAT_CHANGE = 'inputStatsChange';
export const SR_UPLOAD_DIRECTORY_CONTENT = 'uploadDirectoryContent';
export const SR_FILE_TRANSFER_NOTIFY_DIR_CHANGE = 'iNotifyDirChange';
export const SR_USER_SESSIONS = 'dmng_web_studio_session';
export const SR_TIMEOUT_WARNING = 'timeoutWarning'
export const SR_USER_ROLE = 'user_role';
export const SR_NEW_TOKEN = 'newToken';
export const SR_WAIT_FOR_SERVER = 'waitForServer';
export const SR_SERVER_READY = 'serverReady';
export const SR_HELP_LINK = 'helpLink';
export const SR_DECRYPT_PASSWORD = 'decryptPassword'
export const SR_CHECK_INTERCOM = 'checkIntercom';
export const SR_AES67_SOURCES_CHANGE = 'aes67SourcesList';
export const SR_UPLOAD_FIRMWARE = 'uploadFirmwareData';
export const SR_UPLOAD_FIRMWARE_END = 'uploadFirmwareEnd';
export const SR_ASK_FOR_UPDATE_REBOOT = 'askForUpdateReboot';
export const SR_ASK_FOR_UPDATE_VIDEOBOARD = 'askForUpdateVideoBoard';
export const SR_INSTALL_LICENSE = 'installLicense';
export const SR_INSTALL_LICENSE_SMPTE2110 = 'installLicenseSMPTE2110'
export const SR_PTP_CLOCK = 'getPtpClockStatus';
export const SR_NMOS_REG_STATUS = 'getNmosRegStatus';
export const SR_REPORT_PENDING = 'getReportPending';
export const SR_REPORT_FILE = 'getReportFile';
export const SR_EXPORT_CONFIG = 'exportConfigFile';
export const SR_EXPORT_STREAMHUB_PROFILE = 'getStreamHubProfileFile';
export const SR_GET_INTERFACE_STATUS = 'GetInterfaceStatusLite';
export const SR_START_TIMER = 'startTimer';
export const SR_STOP_TIMER = 'stopTimer';
export const SR_ABORT_TIMER = 'abortTimer';
export const SR_I18N_AVAILABLE = 'i18nAvailable';
export const SR_FTP_TEST = 'coremngFTPTransferTest';
export const SR_ENCODER_STATUS = 'getEncoderStatus';
export const SR_ENCODER_PREVIEW_CHANGE = 'encoderPreviewChange';
export const SR_INPUT_TMP_PROFILE_RESULT = 'inputTmpProfileResult';
export const SR_REMOTE_CONTROL_OPEN = 'remoteControlOpen';

export const SE_TIMEOUT_RESET = 'timeoutReset'
export const SE_UPLOAD_DIRECTORY_CONTENT = 'uploadDirectoryContent';
export const SE_DELETE_FILE = 'deleteFileSelection';
export const SE_GET_NDI_STREAMS = 'getNDIStreams';
export const SE_PREP_NDI_FINDER = 'prepNDIFinder';
export const SE_RESET_LOG_COUNTER = 'resetLogCounter';
export const SE_GET_LOG_FILE = 'getLogFile';
export const SE_LOGS = 'coremngLogs';
export const SE_PLAYBACK = 'playback';
export const SE_PLAYBACK_START = 'playbackPlay';
export const SE_PLAYBACK_PAUSE = 'playbackPause';
export const SE_PLAYBACK_RESUME = 'playbackResume';
export const SE_PLAYBACK_SEEK = 'playbackSeek';
export const SE_PLAYBACK_STOP = 'playbackEject';
export const SE_PLAYBACK_LOOP = 'setPlaybackLooping';
export const SE_CHANNEL_INIT = 'channelInit';
export const SE_CHANNEL_CLOSE = 'channelClose';
export const SE_SET_VIDEO_CAPPED_BITRATE = 'setVideoCappedBitrate';
export const SE_SET_LATENCY = 'setLatency';
export const SE_RESET_PACKET_LOST = 'resetPacketLost';
export const SE_ACCOUNTS_UNBLOCK = 'accountsUnlock';
export const SE_FTP_TEST = 'testFTPTransfer';
export const SE_SET_PROFILE_CONFIG = 'setProfileConfig';
export const SE_DELETE_INPUT_PROFILE = 'deleteInputProfile';
export const SE_RESTART_STREAMHUB = 'restartStudio';
export const SE_REBOOT_SERVER = 'rebootServer';
export const SE_HALT_SERVER = 'haltServer';
export const SE_UPDATE_FIRMWARE_START = 'startUploadFirmware';
export const SE_UPDATE_FIRMWARE = 'uploadFirmware';
export const SE_CONFIRM_UPDATE_REBOOT = 'confirmUpdateReboot';
export const SE_UPDATE_VIDEO_CARD = 'updateVideoBoard';
export const SE_UPDATE_VIDEO_CARD_CONFIRM = 'confirmUpdateVideoBoard';
export const SE_ADD_LOG = 'addLog';
export const SE_OPEN_INTERCOM = 'openIntercom';
export const SE_CLOSE_INTERCOM = 'closeIntercom';
export const SE_GET_AES67_SOURCES_LIST = 'getAes67SourcesList';
export const SE_DECRYPT_PASSWORD = 'decryptPassword';
export const SE_START_AUDIO_PATTERN = 'startAudioPattern';
export const SE_STOP_AUDIO_PATTERN = 'stopAudioPattern';
export const SE_INSTALL_LICENSE = 'installLicense';
export const SE_INSTALL_LICENSE_SMPTE2110 = 'installLicenseSMPTE2110';
export const SE_GET_REPORT = 'getReport';
export const SE_EXPORT_CONFIG = 'exportConfig';
export const SE_IMPORT_CONFIG = 'importConfig';
export const SE_FACTORY_RESET_CONFIG = 'factoryResetConfig';
export const SE_GET_STREAMHUB_PROFILE = 'getStreamHubProfile';
export const SE_SET_OBJECT_CONFIG = 'setObjectConfig';
export const SE_SET_OBJECT_CONFIG_WITH_PASSWORD = 'setObjectConfigWithPassword';
export const SE_SET_ITEM_CONFIG = 'setItemConfig';
export const SE_RENAME_STUDIO_FILE = 'renameStudioFile';
export const SE_CHANNEL_DISCONNECT = 'channelDisconnect';
export const SE_PLAY_STREAM = 'playStream';
export const SE_PLAY_NDI_STREAM = 'playNDIStream';
export const SE_EJECT_VIDEO_IFB = 'ejectVideoIFB';
export const SE_START_LIVE = 'startLive';
export const SE_STOP_LIVE = 'stopLive';
export const SE_STOP_FORWARD = 'stopForward';
export const SE_UNIT_COMMAND = 'unitCommand';
export const SE_RECODERENC = 'recorderEnc';
export const SE_LIVE_PROFILE = 'setLiveProfile';
export const SE_UPLOAD_USER_FILE = 'uploadUserFile';
export const SE_INPUT_TMP_PROFILE = 'setInputTmpProfile';
export const SE_REMOTE_CONTROL_TOKEN_REPLACE = 'remoteControlTokenReplace';
export const SE_GET_OUTPUTS_STATUS = 'getOutputStatus';
