import React from "react";
import Thumbnail from "../../../../common/thumbnail/index";
import PropTypes from "prop-types";
import {DragSource} from "react-dnd";
import {DND_ITEM_TYPE_INPUT, STATUS_ERROR, STATUS_LIVE, STATUS_WARNING} from "../../../../../constants";
import AudioLevels from "../../../../common/audio-levels/index";
import InputSelector from "../../../../common/input-selector/index";
import { Button } from "reactstrap";
import {inputIpProfilePropTypes, inputPropTypes} from "../../../../../utils/models-prop-types";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import { AWControlGridItemThumbnail } from "@aviwest/ui-kit";
import { Translate } from "react-localize-redux";

const propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onAudioClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  inputs: PropTypes.arrayOf(inputPropTypes).isRequired,
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes).isRequired,
  light: PropTypes.bool.isRequired,
  audible: PropTypes.bool.isRequired
};

const dndInputSource = {
  beginDrag(props) {
    return {
      id: props.input.id
    };
  }
};

const dndCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
};

const GridItem = (props) => {
  const { input, light, audible, onAudioClick, onInputChange,inputStatus } = props;

  const warningMessage = (inputStatus && inputStatus.status === STATUS_WARNING) ? <Translate id={`messageInfo.${inputStatus.message}.text`} /> : null;
  const errorMessage = (inputStatus && inputStatus.status === STATUS_ERROR) ? <Translate id={`messageInfo.${inputStatus.message}.text`} /> : null;

  return props.connectDragSource(
    <div className={ `grid-item  ${light ? ' light' : ''}${ audible ? ' audible' : ''}` }>
      <div className="grid-item-header">
        {input.status === STATUS_LIVE &&
          <AudioLevels horizontal={true}
                      inputId={input.id}
                      showDisabledBars={false}
                      showScale={false}/>
        }
      </div>
      <div>
        <AWControlGridItemThumbnail className='' warning={warningMessage} error={errorMessage}>
          <div className="thumb">
            {input.status === STATUS_LIVE &&
              <Thumbnail inputId={input.id} timestamp={new Date().getTime()}/>
            }
          </div>
        </AWControlGridItemThumbnail>
      </div>
      <div className="grid-item-footer">
        <InputSelector inputId={input.id}
                       includeEncoders={false}
                       includeMultiView={false}
                       disabled={false}
                       onChange={ onInputChange }/>
        <div className="audio-selection">
          <Button id="multiview_volumeOnOff"
                  className="basic"
                  onClick={ onAudioClick }>
            <AWIcon name={ audible ? "volume_on" : "volume_mute" }/>
          </Button>
        </div>
      </div>
    </div>
  );
};

GridItem.propTypes = propTypes;

export default DragSource(DND_ITEM_TYPE_INPUT, dndInputSource, dndCollect)(GridItem);