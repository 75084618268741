import io from 'socket.io-client';

import {appendBannerMessage, clearBannerMessage} from "./banner.actions";
import {emittingConfig, closeFirmwareModal} from "./socket.actions";
import {logOut} from "../components/pages/login/login.actions";
import {
  maxStreamingOutputOrder,
  nextIndexForProfileConfig,
  numberItemConfig,
  textItemConfig,
  toggleHardwareOutputConfig,
  toggleNDIOutputConfig,
  toggleInputConfig,
  toggleIPOutputConfig,
  booleanItemConfig
} from "../utils/config-utils";
import {reindexProfileConfigList} from "../utils/global-utils";

import AWRxStoreFactory from '@aviwest/ui-kit/dist/js/rx/rx-store-factory';

import {
  ADD_LOG,
  BANNER_MESSAGE_CONFIRM_EXPORT_CONFIG,
  BANNER_MESSAGE_CONFIRM_EXPORT_STREAMHUB_PROFILE,
  BANNER_MESSAGE_CONFIRM_GET_LOGS,
  BANNER_MESSAGE_CONFIRM_GET_REPORT,
  BANNER_MESSAGE_RECONNECTION_ATTEMPT,
  BANNER_MESSAGE_SERVER_ERROR,
  BANNER_MESSAGE_SERVER_SUCCESS,
  BANNER_MESSAGE_TIMEOUT_WARNING,
  BANNER_MESSAGE_REQUEST_AUTHENTIFICATION_KEY,
  BANNER_MESSAGE_UPLOAD_IN_PROGRESS,
  DASHBOARD_ENCODER_ATTACH_PROFILE,
  DASHBOARD_ENCODER_DETACH_PROFILE,
  DASHBOARD_ENCODER_START,
  DASHBOARD_ENCODER_STOP,
  DASHBOARD_ENCODER_SWITCH_INPUT,
  DASHBOARD_ENCODER_RECORD_START,
  DASHBOARD_ENCODER_RECORD_STOP,
  DASHBOARD_INPUT_ATTACH_PROFILE,
  DASHBOARD_INPUT_CHANGE_LATENCY,
  DASHBOARD_INPUT_CHANGE_VIDEO_CAPPED_BITRATE,
  DASHBOARD_INPUT_DETACH_PROFILE,
  DASHBOARD_INPUT_DISCONNECT,
  DASHBOARD_INPUT_INTERCOM_AUDIO_PATTERN,
  DASHBOARD_INTERCOM_MUTE,
  DASHBOARD_INPUT_INTERCOM_START,
  DASHBOARD_INPUT_INTERCOM_STOP,
  DASHBOARD_INPUT_PLAYBACK_PAUSE,
  DASHBOARD_INPUT_PLAYBACK_PLAY,
  DASHBOARD_INPUT_PLAYBACK_SEEK,
  DASHBOARD_INPUT_PLAYBACK_START,
  DASHBOARD_INPUT_PLAYBACK_STOP,
  DASHBOARD_INPUT_PLAYBACK_LOOP_ON,
  DASHBOARD_INPUT_PLAYBACK_LOOP_OFF,
  DASHBOARD_INPUT_VIDEO_IFB_ENCODER_BIND,
  DASHBOARD_INPUT_VIDEO_IFB_SOURCE_BIND,
  DASHBOARD_INPUT_RECORD_START,
  DASHBOARD_INPUT_RECORD_STOP,
  DASHBOARD_INPUT_EJECT_VIDEO_IFB,
  DASHBOARD_INPUT_REMOTE_LIVE_START,
  DASHBOARD_INPUT_REMOTE_LIVE_STOP,
  DASHBOARD_INPUT_REMOTE_FORWARD_STOP,
  DASHBOARD_INPUT_REMOTE_PROFILE_SWITCH,
  DASHBOARD_INPUT_RESET_DROPPED_PACKETS,
  DASHBOARD_INPUT_START,
  DASHBOARD_INPUT_STOP,
  DASHBOARD_INPUT_SUBSCRIBE,
  DASHBOARD_INPUT_UNSUBSCRIBE,
  DASHBOARD_INPUT_RECONNECT_MODEM,
  DASHBOARD_INPUT_DISCONNECT_MODEM,
  DASHBOARD_INPUT_REMOTE_CONTROL_TOKEN_REPLACE,
  DASHBOARD_MULTIVIEW_CHANGE_GRID,
  DASHBOARD_MULTIVIEW_SHOW_OVERLAY,
  DASHBOARD_MULTIVIEW_START,
  DASHBOARD_MULTIVIEW_STOP,
  DASHBOARD_MULTIVIEW_SWITCH_AUDIO,
  DASHBOARD_OUTPUT_ATTACH_PROFILE,
  DASHBOARD_OUTPUT_DETACH_PROFILE,
  DASHBOARD_OUTPUT_ATTATCH_NDI_PROFILE,
  DASHBOARD_OUTPUT_START,
  DASHBOARD_OUTPUT_STOP,
  DASHBOARD_OUTPUT_LOCK,
  DASHBOARD_OUTPUT_UNLOCK,
  DASHBOARD_OUTPUT_SWITCH_INPUT,
  DASHBOARD_OUTPUTS_PERSIST_POSITIONS,
  FILES_DELETE_FILE,
  FILES_UPLOAD_DIRECTORY_CONTENT,
  INTERCOM_OPEN,
  DECRYPT_PASSWORD,
  LICENSE_UPLOAD,
  LICENSE_UPLOAD_SMPTE2110,
  LOGGED_IN,
  LOGGED_OUT,
  OUTPUT_TYPE_HARDWARE,
  OUTPUT_TYPE_NDI,
  OUTPUT_TYPE_IP,
  PASSWORD_SAMBA_UPDATE,
  PASSWORD_UPDATE,
  RENAME_STUDIO_FILE,
  TIMEOUT_RESET,
  SE_TIMEOUT_RESET,
  SE_ACCOUNTS_UNBLOCK,
  SE_ADD_LOG,
  SE_CHANNEL_CLOSE,
  SE_CHANNEL_DISCONNECT,
  SE_CHANNEL_INIT,
  SE_CLOSE_INTERCOM,
  SE_CONFIRM_UPDATE_REBOOT,
  SE_DELETE_FILE,
  SE_EXPORT_CONFIG,
  SE_FACTORY_RESET_CONFIG,
  SE_FTP_TEST,
  SE_GET_LOG_FILE,
  SE_LOGS,
  SE_GET_REPORT,
  SE_GET_STREAMHUB_PROFILE,
  SE_HALT_SERVER,
  SE_IMPORT_CONFIG,
  SE_INSTALL_LICENSE,
  SE_INSTALL_LICENSE_SMPTE2110,
  SE_LIVE_PROFILE,
  SE_OPEN_INTERCOM,
  SE_DECRYPT_PASSWORD,
  SE_PLAY_STREAM,
  SE_PLAY_NDI_STREAM,
  SE_PLAYBACK,
  SE_PLAYBACK_PAUSE,
  SE_PLAYBACK_RESUME,
  SE_PLAYBACK_SEEK,
  SE_PLAYBACK_START,
  SE_PLAYBACK_STOP,
  SE_REBOOT_SERVER,
  SE_RENAME_STUDIO_FILE,
  SE_RESET_PACKET_LOST,
  SE_RESTART_STREAMHUB,
  SE_SET_ITEM_CONFIG,
  SE_SET_LATENCY,
  SE_SET_OBJECT_CONFIG,
  SE_SET_OBJECT_CONFIG_WITH_PASSWORD,
  SE_SET_PROFILE_CONFIG,
  SE_SET_VIDEO_CAPPED_BITRATE,
  SE_START_AUDIO_PATTERN,
  SE_EJECT_VIDEO_IFB,
  SE_START_LIVE,
  SE_STOP_AUDIO_PATTERN,
  SE_STOP_LIVE,
  SE_STOP_FORWARD,
  SE_UNIT_COMMAND,
  SE_RECODERENC,
  SE_UPDATE_FIRMWARE,
  SE_UPDATE_FIRMWARE_START,
  SE_UPDATE_VIDEO_CARD,
  SE_UPDATE_VIDEO_CARD_CONFIRM,
  SE_UPLOAD_DIRECTORY_CONTENT,
  SE_UPLOAD_USER_FILE,
  SE_GET_NDI_STREAMS,
  SE_PREP_NDI_FINDER,
  SE_REMOTE_CONTROL_TOKEN_REPLACE,
  SETTINGS_ENCODER_PROFILE_CREATE,
  SETTINGS_ENCODER_PROFILE_DELETE,
  SETTINGS_ENCODER_PROFILE_UPDATE,
  SETTINGS_CUSTOM_PATTERN_UPDATE,
  SETTINGS_FILE_TRANSFER_CREATE,
  SETTINGS_FILE_TRANSFER_DELETE,
  SETTINGS_FILE_TRANSFER_TEST,
  SETTINGS_FILE_TRANSFER_UPDATE,
  SETTINGS_GENERAL_EXPORT_CONFIG,
  SETTINGS_GENERAL_EXPORT_STREAMHUB_PROFILE,
  SETTINGS_GENERAL_IMPORT_CONFIG,
  SETTINGS_GENERAL_RESET_CONFIG,
  SETTINGS_GENERAL_UPDATE,
  SETTINGS_INPUT_IP_PROFILE_CREATE,
  SETTINGS_INPUT_IP_PROFILE_DELETE,
  SETTINGS_INPUT_IP_PROFILE_UPDATE,
  SETTINGS_INPUT_SAVE,
  SETTINGS_INTERCOM_UPDATE,
   SETTINGS_INTERCOM_INPUTS_UPDATE,
  SETTINGS_INTERCOM_OUTPUTS_UPDATE,
  SETTINGS_GET_AES67_SOURCES_LIST, 
  SETTINGS_MEDIA_FILE_CLEANER_UPDATE,
  SETTINGS_MEDIA_RECEIVED_FILES_UPDATE,
  SETTINGS_MEDIA_RECORDED_FILES_UPDATE,
  SETTINGS_MEDIA_RENAME_FILES_UPDATE,
  SETTINGS_MULTIVIEW_SAVE,
  SETTINGS_NETWORK_UPDATE,
  SETTINGS_NDI_UPDATE,
  SETTINGS_NETWORK_ADVANCED_UPDATE,
  SETTINGS_GET_NDI_STREAMS,
  SETTINGS_PREP_NDI_FINDER,
  SETTINGS_OUTPUT_HARDWARE_SAVE,
  SETTINGS_OUTPUT_NDI_SAVE,
  SETTINGS_OUTPUT_IP_PROFILE_CREATE,
  SETTINGS_OUTPUT_IP_PROFILE_DELETE,
  SETTINGS_OUTPUT_IP_PROFILE_UPDATE,
  SETTINGS_SECURITY_UNBLOCK_ACCOUNTS,
  SETTINGS_SECURITY_UPDATE,
  SETTINGS_LDAP_UPDATE,
  SETTINGS_VIDEOCARDS_UPDATE,
  SR_ABORT_TIMER,
  SR_ABUS_PROXY_READY,
  SR_ASK_FOR_UPDATE_REBOOT,
  SR_ASK_FOR_UPDATE_VIDEOBOARD,
  SR_AUDIO_DEVICES,
  SR_CAPABILITIES,
  SR_CHANNEL_STATUS,
  SR_CHANNELS_INFO,
  SR_CHANNELS_VOLUME,
  SR_CHECK_INTERCOM,
  SR_CONFIG,
  SR_CONNECT,
  SR_CONNECT_ERROR,
  SR_DEVICE_INFO,
  SR_ENCODER_PREVIEW_CHANGE,
  SR_ENCODER_STATUS,
  SR_ERROR,
  SR_EXPORT_CONFIG,
  SR_EXPORT_STREAMHUB_PROFILE,
  SR_FILE_TRANSFER_NOTIFY_DIR_CHANGE,
  SR_FTP_TEST,
  SR_GET_INTERFACE_STATUS,
  SR_HARDWARE_OUTPUT_PREVIEW_CHANGE,
  SR_NDI_OUTPUT_PREVIEW_CHANGE,
  SR_NDI_STREAMS_CHANGE,
  SR_HELP_LINK,
  SR_DECRYPT_PASSWORD,
  SR_I18N_AVAILABLE,
  SR_INPUT_PREVIEW_CHANGE,
  SR_INPUTS_STAT_CHANGE,
  SR_INSTALL_LICENSE,
  SR_INSTALL_LICENSE_SMPTE2110,
  SR_PTP_CLOCK,
  SR_NMOS_REG_STATUS,
  SR_IP_LOCAL,
  SR_IP_PUBLIC,
  SR_IP_SMPTE,
  SR_LICENSE,
  SR_LIVE_INFO,
  SR_META_DATA,
  SR_LIVE_PROFILE,
  SR_LOG_FILE,
  SR_LOGS,
  SR_MONITOR_INFO,
  SR_TRANSCODER_STATUS,
  SR_MOSAIC_PREVIEW_CHANGE,
  SR_NEW_LOG,
  SR_OUTPUT_STATS,
  SR_OUTPUT_STATUS,
  SR_PREVIEW_CHANGE,
  SR_RECONNECT_ATTEMPT,
  SR_RECONNECT_FAILED,
  SR_REPORT_FILE,
  SR_REPORT_PENDING,
  SR_SERVER_ERROR,
  SR_SERVER_SUCCESS,
  SR_START_TIMER,
  SR_STATUS_DEVICES_CHANGE,
  SR_STOP_TIMER,
  SR_UPLOAD_DIRECTORY_CONTENT,
  SR_UPLOAD_FIRMWARE,
  SR_USER_ROLE,
  SR_USER_SESSIONS,
  SR_NEW_TOKEN,
  SR_TIMEOUT_WARNING,
  SR_WAIT_FOR_SERVER,
  SR_SERVER_READY,
  STATUS_OFF,
  TOOLS_LOGS_EXPORT,
  TOOLS_LOGS_PAGE_CHANGE,
  TOOLS_LOGS_FILTERS_CHANGE,
  TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE,
  TOOLS_SYSTEM_GENERATE_REPORT,
  TOOLS_SYSTEM_HALT_SERVER,
  TOOLS_SYSTEM_REBOOT_SERVER,
  TOOLS_SYSTEM_RESTART_STREAMHUB,
  TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_REBOOT,
  TOOLS_SYSTEM_UPLOAD_FIRMWARE_START,
  SETTINGS_INPUT_TMP_PROFILE_CREATE,
  SE_INPUT_TMP_PROFILE, SR_INPUT_TMP_PROFILE_RESULT, WEBRTC, SE_DELETE_INPUT_PROFILE, SR_ORION_PLATFORM,
  SR_REMOTE_CONTROL_OPEN,
  SE_PLAYBACK_LOOP,
  SE_GET_AES67_SOURCES_LIST,
  SR_AES67_SOURCES_CHANGE,
  SR_UPLOAD_FIRMWARE_END,
  DASHBOARD_OUTPUT_ATTATCH_HARDWARE_PROFILE,
  DASHBOARD_OUTPUT_DETATCH_NDI_PROFILE,
  DASHBOARD_OUTPUT_DETATCH_HARDWARE_PROFILE,
  SE_GET_OUTPUTS_STATUS
} from '../constants';

import Logger from '../utils/logger';
import {
  intercomOpen,
  serverWaiting,
  updateFromAudioDevices,
  updateFromCapabilities,
  updateFromChannelStatus,
  updateFromConfigAndSubscribeIfNecessary,
  updateFromEncoderPreview,
  updateFromEncodersStatus,
  updateFromNDIStreamsChange,
  updateFromeas67Change,
  updateFromIPLocal,
  updateFromSMPTEIP,
  updateFromIPPublic,
  updateFromLiveInfo,
  updateFromMetaData,
  updateFromLiveProfile,
  updateFromLogs,
  updateFromMonitorInfo,
  updateFromTranscoderStatus,
  updateFromNetworkInterfaceStatuses,
  updateFromNetworkStreamhubInterfaceStatuses,
  updateFromNewLog,
  updateFromOutputStats,
  updateFromOutputStatus,
  updateFromStatusDevicesChange,
  updateFromStreamStat,
  updateFromUserSessions,
  updateFTPTest,
  updateHelpLink,
  updateDecryptPassword,
  updateInputIntercomInfo,
  updateInputIntercomVolume,
  updateLanguages,
  updateLicense,
  updateReportStatus,
  updateStreamHubMetadata,
  updateUserRole,
  updateIdentityToken,
  updateFromOrionPlatform,
  updatePtpClockStatus,
  updateNmosRegStatus
} from "./socket.actions";

import {updateUploadDirectoryContent} from "../components/pages/files/files.actions";
import {
  checkVideoCardFirmwareResult,
  uploadFirmwareResult,
  uploadingFirmwareData
} from "../components/pages/tools/system/system.tools.actions";
import {licenseUploadResponse, licenseUploadResponseSMPTE2110} from "./license.actions";
import {extractDirectoryPath} from "../utils/global-utils";
import {inputTmpProfileResult} from "../components/pages/settings/settings.actions";

export default ({ socketUrl  }) => {

  const url = socketUrl ? socketUrl : '';
  let socket = null;
  let prematureActions = [];
  let configReady = false;

  return (store) => {

    const dispatchAction = (action, waitUntilReady = false, isConfig = false) => {
      // Delaying some actions (SR_ENCODER_STATUS, SR_OUTPUT_STATUS, SR_CONFIG) until a license is received
      const licenseReady = store.getState().license.details !== null;
      if (isConfig) {
        configReady = true;
      }

      if(waitUntilReady && !licenseReady){
        prematureActions.push(action);
        Logger.info("[Socket Middleware] Delaying action: ", action);
        return;
      }
      store.dispatch(action);
      if(licenseReady && prematureActions.length > 0 && configReady){
        prematureActions = prematureActions.filter(prematureAction => { // Hack to treat each element once, preserving initial order using .filter()
          store.dispatch(prematureAction);
          Logger.info("[Socket Middleware] Handling premature action: ", prematureAction);
          return false;
        });
      }
    };

    const listenToActions = (socket) => {

      socket.on(SR_CONNECT, () => {
        Logger.info('[Socket Middleware] Connection success');
        dispatchAction(clearBannerMessage(BANNER_MESSAGE_RECONNECTION_ATTEMPT));
        dispatchAction(serverWaiting('noConnection', false));
        dispatchAction(closeFirmwareModal());
      });

      socket.on(SR_CONNECT_ERROR, (error) => {
        Logger.error('[Socket Middleware] Connection error:', error);
        if(error.message === 'token expired' || error.message === 'invalid token') {
          dispatchAction(logOut());
        }
        else {
          dispatchAction(serverWaiting('noConnection', true));
        }
      });

      socket.on(SR_RECONNECT_ATTEMPT, () => {
        Logger.info('[Socket Middleware] Reconnection attempt');
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_RECONNECTION_ATTEMPT));
      });

      socket.on(SR_RECONNECT_FAILED, () => {
        Logger.error('[Socket Middleware] Reconnection failed');

      });

      socket.on(SR_ERROR, (error) => {
        if(error === 'token expired' || error === 'invalid token') {
          Logger.error('[Socket Middleware] Token expired');
          dispatchAction(logOut());
        }
        else {
          Logger.error('[Socket Middleware] Unknown error', error);
        }
      });

      socket.on(SR_SERVER_ERROR, (data) => {
        Logger.error('[Socket Middleware] Server error', data);
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_SERVER_ERROR, { key: data }));
      });

      socket.on(SR_SERVER_SUCCESS, (data) => {
        Logger.info('[Socket Middleware] Server success', data);

        // Logout if admin password has been changed
        if (store.getState().identity.changePasswordRequired && data === 'PASSWORD_UPDATED_SUCCESSFULLY') {
          dispatchAction(logOut());
        }
        else if (data === 'LICENSE_CHANGED') {
          window.location.reload();
        } else {
          dispatchAction(appendBannerMessage(BANNER_MESSAGE_SERVER_SUCCESS, { key: data }));
        }
      });

      socket.on(SR_WAIT_FOR_SERVER, (data) => {
        Logger.info('[Socket Middleware] Waiting for server', data);
        dispatchAction(serverWaiting(data, true));
      });

      socket.on(SR_SERVER_READY, () => {
        Logger.info('[Socket Middleware] Server Ready');
        window.location.href = '/';
      });

      socket.on(SR_I18N_AVAILABLE, (data) => {
        Logger.info('[Socket Middleware] i18n available ', data);
        dispatchAction(updateLanguages(data));
      });

      socket.on(SR_TIMEOUT_WARNING, () => {
        Logger.info('[Socket Middleware] Timeout warning');
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_TIMEOUT_WARNING));
      });

      socket.on(SR_USER_ROLE, (data) => {
        Logger.info('[Socket Middleware] User role', data);
        const { role, changePasswordRequired } = data;
        dispatchAction(updateUserRole(role, changePasswordRequired));
      });

      socket.on(SR_NEW_TOKEN, (token) => {
        Logger.info('[Socket Middleware] New token', token);
        dispatchAction(updateIdentityToken(token));
      });

      socket.on(SR_ABUS_PROXY_READY, (data) => {
        Logger.info('[Socket Middleware] Abus proxy ready ', data);
        dispatchAction(updateStreamHubMetadata(data));
      });

      socket.on(SR_DEVICE_INFO, (data) => {
        Logger.info('[Socket Middleware] Device info ', data.result);
        dispatchAction(updateStreamHubMetadata(data.result));
      });

      socket.on(SR_CONFIG, (data) => {
        const config = JSON.parse(data.result.config);
        Logger.info('[Socket Middleware] Config ', config);
        dispatchAction(updateFromConfigAndSubscribeIfNecessary(config), true, true);
      });

      socket.on(SR_CAPABILITIES, (data) => {
        const capabilities = data.result;
        Logger.info('[Socket Middleware] Capabilities ', capabilities);
        dispatchAction(updateFromCapabilities(capabilities));
      });

      socket.on(SR_LICENSE, (data) => {
        const licenseData = data.result;
        Logger.info('[Socket Middleware] License ', licenseData);
        if(licenseData.hasOrionConnectionKey !== true && licenseData.status === 'valid'){
          dispatchAction(appendBannerMessage(BANNER_MESSAGE_REQUEST_AUTHENTIFICATION_KEY));
        }
        dispatchAction(updateLicense(licenseData, store.getState().config));
      });

      socket.on(SR_ORION_PLATFORM, (data) => {
        const platformData = data.result;
        Logger.info('[Socket Middleware] Orion Platform ', data);
        dispatchAction(updateFromOrionPlatform(platformData, store.getState().config));
      });

      socket.on(SR_CHANNEL_STATUS, (data) => {
        const channelStatus = data.result;
        Logger.info('[Socket Middleware] Channel status ', channelStatus);
        dispatchAction(updateFromChannelStatus(channelStatus));
      });

      socket.on(SR_CHANNELS_INFO, (data) => {
        const channelsInfo = data.result;
        // Logger.info('[Socket Middleware] Channels info ', channelsInfo);
        dispatchAction(updateInputIntercomInfo(channelsInfo));
      });

      socket.on(SR_CHANNELS_VOLUME, (data) => {
        const channelsVolume = data.result;
        // Logger.info('[Socket Middleware] Channels volume ', channelsVolume);
        dispatchAction(updateInputIntercomVolume(channelsVolume));
      });

      socket.on(SR_START_TIMER, (data) => {
        Logger.info('[Socket Middleware] Start timer ', data);
      });

      socket.on(SR_STOP_TIMER, (data) => {
        Logger.info('[Socket Middleware] Stop timer ', data);
      });

      socket.on(SR_ABORT_TIMER, (data) => {
        Logger.info('[Socket Middleware] Abort timer ', data);
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_SERVER_ERROR, { key: 'ABORT_TIMER' }));
      });

      socket.on(SR_STATUS_DEVICES_CHANGE, (data) => {
        Logger.info('[Socket Middleware] Devices status change ', data.result);
        dispatchAction(updateFromStatusDevicesChange(data.result));
      });

      socket.on(SR_LIVE_PROFILE, (data) => {
        Logger.info('[Socket Middleware] Live profiles ', data);
        dispatchAction(updateFromLiveProfile(data));
      });

      socket.on(SR_LIVE_INFO, (data) => {
        Logger.info('[Socket Middleware] Live info ', data);
        dispatchAction(updateFromLiveInfo(data));
      });

      socket.on(SR_META_DATA, (data) => {
        Logger.info('[Socket Middleware] Meta data ', data);
        dispatchAction(updateFromMetaData(data));
      });

      socket.on(SR_GET_INTERFACE_STATUS, (data) => {
        if(data.port === 4000){
          Logger.info('[Socket Middleware] Streamhub Interface status ', data);
          dispatchAction(updateFromNetworkStreamhubInterfaceStatuses(data))
        }else {
          Logger.info('[Socket Middleware] Interface status ', data);
          dispatchAction(updateFromNetworkInterfaceStatuses(data));
        }
      });

      socket.on(SR_ENCODER_STATUS, (data) => {
        Logger.info('[Socket Middleware] Encoder status ', data);
        dispatchAction(updateFromEncodersStatus(data.result), true);
      });

      socket.on(SR_ENCODER_PREVIEW_CHANGE, (data) => {
        // Logger.info('[Socket middleware] Encoder preview change', data);
        if(data.result && data.result.encoderId){
          data.result.thumbnail && AWRxStoreFactory.getBasicStore('encoderThumbs').pushDataToSubject(data.result.encoderId, 'data:image/jpeg;base64,' + data.result.thumbnail)
          data.result.audioLevels && AWRxStoreFactory.getBasicStore('encoderAudioLevels').pushDataToSubject(data.result.encoderId, data.result.audioLevels)
          dispatchAction(updateFromEncoderPreview(data.result));
        }
      });

      socket.on(SR_OUTPUT_STATUS, (data) => {
        const outputStatus = data.result;
        if(outputStatus){
          Logger.info('[Socket Middleware] Output status ', outputStatus);
          dispatchAction(updateFromOutputStatus(outputStatus), true);
        }
      });

      const inputPreviewHandler = (data) => {
        // Logger.info('[Socket Middleware] Input Preview change ', data);
        if(data.result == null && data.channelId != null){
          data.result = {
            inputId: data.channelId,
            thumbnail: data.thumbnail
          };
        }
        if(data.result && data.result.inputId){
          data.result.thumbnail && AWRxStoreFactory.getBasicStore('inputThumbs').pushDataToSubject(data.result.inputId, 'data:image/jpeg;base64,' + data.result.thumbnail)
          data.result.audioLevels && AWRxStoreFactory.getBasicStore('inputAudioLevels').pushDataToSubject(data.result.inputId, data.result.audioLevels)
        }
      };

      socket.on(SR_INPUT_PREVIEW_CHANGE, inputPreviewHandler);

      socket.on(SR_PREVIEW_CHANGE, inputPreviewHandler);

      socket.on(SR_HARDWARE_OUTPUT_PREVIEW_CHANGE, (data) => {
        // Logger.info('[Socket Middleware] Hardware output preview change ', data);
        if(data.result && data.result.outputId){
          data.result.thumbnail && AWRxStoreFactory.getBasicStore('outputThumbs').pushDataToSubject(data.result.outputId, 'data:image/jpeg;base64,' + data.result.thumbnail)
          data.result.audioLevels && AWRxStoreFactory.getBasicStore('outputAudioLevels').pushDataToSubject(data.result.outputId, data.result.audioLevels)
        }
      });

      socket.on(SR_NDI_OUTPUT_PREVIEW_CHANGE, (data) => {
        if(data.result && data.result.outputId){
          data.result.thumbnail && AWRxStoreFactory.getBasicStore('ndiOutputThumbs').pushDataToSubject(data.result.outputId, 'data:image/jpeg;base64,' + data.result.thumbnail)
          data.result.audioLevels && AWRxStoreFactory.getBasicStore('ndiOutputAudioLevels').pushDataToSubject(data.result.outputId, data.result.audioLevels)
        }
      });

      socket.on(SR_NDI_STREAMS_CHANGE, (data) => {
        Logger.info('[SocketMiddleware] Received NDI Streams', data);
        if(data.result){
          dispatchAction(updateFromNDIStreamsChange(data.result));
        }
      });

      socket.on(SR_MOSAIC_PREVIEW_CHANGE, (data) => {
        // Logger.info('[Socket Middleware] Mosaic preview:', data);
        if(data.result && data.result.thumbnail){
          AWRxStoreFactory.getBasicStore('multiViewThumb').pushDataToSubject('multiview', 'data:image/jpeg;base64,' + data.result.thumbnail)
        }
      });

      socket.on(SR_INPUTS_STAT_CHANGE, (data) => {
        // Logger.info('[Socket Middleware] Stream stat ', data);
        dispatchAction(updateFromStreamStat(data));
      });

      socket.on(SR_OUTPUT_STATS, (data) => {
        // Logger.info('[Socket Middleware] Output stats: ', data);
        if(data.result){
          dispatchAction(updateFromOutputStats(data.result));
        }
      });

      socket.on(SR_MONITOR_INFO, (data) => {
        // Logger.info('[Socket Middleware] Monitor info: ', data);
        if(data.result){
          dispatchAction(updateFromMonitorInfo(data.result));
        }
      });

      socket.on(SR_TRANSCODER_STATUS, (data) => {
        Logger.info('[Socket Middleware] Transcoder Status: ', data);
        if(data.result){
          dispatchAction(updateFromTranscoderStatus(data.result));
        }
      });

      socket.on(SR_AUDIO_DEVICES, (data) => {
        Logger.info('[Socket Middleware] Audio Devices: ', data);
        if(data.result){
          dispatchAction(updateFromAudioDevices(data.result));
        }
      });

      socket.on(SR_IP_PUBLIC, (data) => {
        Logger.info('[Socket Middleware] Public IP: ', data);
        dispatchAction(updateFromIPPublic(data, store.getState().config));
      });

      socket.on(SR_IP_LOCAL, (data) => {
        Logger.info('[Socket Middleware] Local IP: ', data);
        dispatchAction(updateFromIPLocal(data, store.getState().config));
      });

      socket.on(SR_IP_SMPTE, (data) => {
        Logger.info('[Socket Middleware] SMPTE 2110 IP: ', data);
        dispatchAction(updateFromSMPTEIP(data, store.getState().config));
      });

      socket.on(SR_USER_SESSIONS, (data) => {
        Logger.info('[Socket Middleware] User sessions: ', data);
        dispatchAction(updateFromUserSessions(data));
      });

      socket.on(SR_FTP_TEST, (data) => {
        Logger.info('[Socket Middleware] FTP test result: ', data);
        dispatchAction(updateFTPTest(data.res));
      });

      socket.on(SR_LOGS, (data) => {
        Logger.info('[SocketMiddleware] Received logs: ', data);
        dispatchAction(updateFromLogs(data));
      });

      socket.on(SR_NEW_LOG, (data) => {
        Logger.info('[SocketMiddleware] Received new log: ', data);
        dispatchAction(updateFromNewLog(data));
      });

      socket.on(SR_LOG_FILE, (data) => {
        Logger.info('[SocketMiddleware] Received log file: ', data);
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_CONFIRM_GET_LOGS, {
          fileName: data.name,
          fileSize: data.size
        }));
      });

      socket.on(SR_UPLOAD_DIRECTORY_CONTENT, (data) => {
        Logger.info('[Socket Middleware] %s', SR_UPLOAD_DIRECTORY_CONTENT, data);
        dispatchAction(updateUploadDirectoryContent(data));
      });

      socket.on(SR_FILE_TRANSFER_NOTIFY_DIR_CHANGE, (data) => {
        Logger.info('[Socket Middleware] %s', SR_FILE_TRANSFER_NOTIFY_DIR_CHANGE, data);
        // Check if reload directory is needed
        if (data.dir && data.dir === store.getState().files.path + '/') {
          socket.emit(SE_UPLOAD_DIRECTORY_CONTENT, data.dir);
        }
      });

      socket.on(SR_HELP_LINK, (data) => {
        Logger.info('[Socket Middleware] Help link: ', data);
        dispatchAction(updateHelpLink(data));
      });

      socket.on(SR_DECRYPT_PASSWORD, (data) => {
        Logger.info('[Socket Middleware] Decrypt password: ', data);
        dispatchAction(updateDecryptPassword(data));
      });

      socket.on(SR_CHECK_INTERCOM, () => {
        Logger.warn('[Socket Middleware] Check intercom');
        const intercomOpened = store.getState().streamhub.intercomOpened;
        // Check if Intercom need to be re-open when an other Web Studio user has closed his Intercom page
        if(intercomOpened){
          dispatchAction(intercomOpen(true));
        }
      });
      socket.on(SR_AES67_SOURCES_CHANGE, (data) => {
         Logger.info('[SocketMiddleware] Received AES67 Source Lists ', data);
         const {event, service, ...filtered } = data.result
        if(data.result.sources){
          dispatchAction(updateFromeas67Change(filtered));
        } 
      });

      socket.on(SR_UPLOAD_FIRMWARE, (start) => {
        Logger.info('[Socket Middleware] Upload firmware data at: ', start);
        const file = store.getState().tools.system.uploadingFirmwareFile;
        if(file){
          const step = 1000000; // step => 1MB
          const reader = new FileReader();
          reader.onload = (loadEvent) => {
            socket.emit(SE_UPDATE_FIRMWARE, loadEvent.target.result, step, file.name);
          };
          // Load a block of selected file
          reader.readAsDataURL(file.slice(start, Number(start) + step));

          dispatchAction(uploadingFirmwareData(Math.round(start / file.size * 100)));
        }
      });

      socket.on(SR_UPLOAD_FIRMWARE_END, () => {
        Logger.warn('[Socket Middleware] Upload firmware end');
        dispatchAction(uploadFirmwareResult(null));
      });

      socket.on(SR_ASK_FOR_UPDATE_REBOOT, (data) => {
        Logger.warn('[Socket Middleware] Update reboot: ', data);
        dispatchAction(uploadFirmwareResult(data));
      });

      socket.on(SR_ASK_FOR_UPDATE_VIDEOBOARD, (data) => {
        if(data.result){
          Logger.info('[Socket Middleware] VideoCard reboot: ', data);
          dispatchAction(checkVideoCardFirmwareResult(data.result.code));
        }
      });

      socket.on(SR_INSTALL_LICENSE, (data) => {
        Logger.warn('[Socket Middleware] Install license: ', data);
        dispatchAction(licenseUploadResponse(data.result));
      });

      socket.on(SR_INSTALL_LICENSE_SMPTE2110, (data) => {
        Logger.warn('[Socket Middleware] Install license: ', data);
        dispatchAction(licenseUploadResponseSMPTE2110(data.result));
      });
      
      socket.on(SR_PTP_CLOCK, (data) => {
        Logger.info('[Socket Middleware] PTP clock Status: ', data);
        dispatchAction(updatePtpClockStatus(data.result));                                                   
      });

      socket.on(SR_NMOS_REG_STATUS, (data) => {
        Logger.info('[Socket Middleware] NMOS Reg Status: ', data);
        dispatchAction(updateNmosRegStatus(data.result));                                                   
      });

      socket.on(SR_REPORT_PENDING, (data) => {
        Logger.warn('[Socket Middleware] Preparing report: ', data);
        dispatchAction(updateReportStatus(data));
      });

      socket.on(SR_REPORT_FILE, (data) => {
        Logger.warn('[Socket Middleware] Download report: ', data);
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_CONFIRM_GET_REPORT, {
          fileName: data.name,
          fileSize: data.size
        }));
      });

      socket.on(SR_EXPORT_CONFIG, (data) => {
        Logger.warn('[Socket Middleware] Export config: ', data);
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_CONFIRM_EXPORT_CONFIG, {
          fileName: data.name,
          fileSize: data.size
        }));
      });

      socket.on(SR_EXPORT_STREAMHUB_PROFILE, (data) => {
        Logger.warn('[Socket Middleware] Streamhub profile: ', data);
        dispatchAction(appendBannerMessage(BANNER_MESSAGE_CONFIRM_EXPORT_STREAMHUB_PROFILE, {
          fileName: data.name,
          fileSize: data.size
        }));
      });

      socket.on(SR_INPUT_TMP_PROFILE_RESULT, (data) => {
        dispatchAction(inputTmpProfileResult(data.name, data.errorCode));
      });

      socket.on(SR_REMOTE_CONTROL_OPEN, (data) => {
        Logger.info('[Socket Middleware] Remote control: ', data);
        window.open(data, 'aw-remote');
        //Logging
        socket.emit(SE_ADD_LOG, "Opening remote control");
      });
    };

    return (next) => (action) => {
      next(action);

      // Open the websocket connect
      if (action.type === LOGGED_IN) {
        const token = action.token;
        socket = io(url, {
          query: { token },
          transports: ['websocket']
        });

        // Now that socket is created, we can subscribe to events
        listenToActions(socket);
      }
      else if (action.type === LOGGED_OUT) {
        if(socket){
          socket.close();
          socket = null;
        }
        // Needed to clear all states (SEE User Story #14864)
        window.location.reload();
      }
      else if(socket != null){
        if(action.type === FILES_UPLOAD_DIRECTORY_CONTENT){
          Logger.info('[SocketMiddleware] Directory content at path:', action.path);
          socket.emit(SE_UPLOAD_DIRECTORY_CONTENT, action.path);
        }
        else if(action.type === RENAME_STUDIO_FILE){
          Logger.info('[SocketMiddleware] Rename studio file:', action.oldPath, action.newPath);
          const path = extractDirectoryPath(action.oldPath);
          socket.emit(SE_RENAME_STUDIO_FILE, path, action.oldPath, action.newPath);
        }
        else if(action.type === FILES_DELETE_FILE){
          Logger.info('[SocketMiddleware] Deleting files: ', action.items);
          socket.emit(SE_DELETE_FILE, store.getState().files.path, action.items);
        }
        else if(action.type === DASHBOARD_INPUT_START){
          const pendingForConfig = store.getState().streamhub.pendingForConfig;
          if (!pendingForConfig) {
            dispatchAction(emittingConfig());
            Logger.info('[SocketMiddleware] Emitting config: ', toggleInputConfig(action.input.ipProfileId, true));
            socket.emit(SE_SET_ITEM_CONFIG, toggleInputConfig(action.input.ipProfileId, true));
          }
        }
        else if(action.type === DASHBOARD_INPUT_STOP){
          const pendingForConfig = store.getState().streamhub.pendingForConfig;
          if (!pendingForConfig) {
            dispatchAction(emittingConfig());
            Logger.info('[SocketMiddleware] Emitting config: ', toggleInputConfig(action.input.ipProfileId, false));
            socket.emit(SE_SET_ITEM_CONFIG, toggleInputConfig(action.input.ipProfileId, false));
          }
        }
        else if(action.type === DASHBOARD_INPUT_DISCONNECT){
          const inputId = parseInt(action.input.id);
          if(!isNaN(inputId)){
            Logger.info('[SocketMiddleware] Emitting "channelDisconnect": ', inputId);
            socket.emit(SE_CHANNEL_DISCONNECT, inputId);
          }
        }
        else if(action.type === DASHBOARD_INPUT_ATTACH_PROFILE){
          const inputProtocolForAttach = { ...store.getState().config.inputProtocol };
          if(action.input.ipProfileId){
            inputProtocolForAttach[action.input.ipProfileId].channelProfileId = -1;
          }
          inputProtocolForAttach[action.profileId].channelProfileId = parseInt(action.input.id);
          Logger.info('[SocketMiddleware] Updating inputProtocol:', inputProtocolForAttach);
          socket.emit(SE_SET_PROFILE_CONFIG, 'inputProtocol', false, JSON.stringify({ inputProtocol: inputProtocolForAttach }));
        }
        else if(action.type === DASHBOARD_INPUT_DETACH_PROFILE){
          const inputProtocolForDetach = { ...store.getState().config.inputProtocol };
          inputProtocolForDetach[action.input.ipProfileId].channelProfileId = -1;
          Logger.info('[SocketMiddleware] Updating inputProtocol: ', inputProtocolForDetach);
          socket.emit(SE_SET_PROFILE_CONFIG, 'inputProtocol', false, JSON.stringify({ inputProtocol: inputProtocolForDetach }));
        }
        else if(action.type === DASHBOARD_INPUT_VIDEO_IFB_ENCODER_BIND){
          if(action.inputId && action.encoderId){
            const encoderIndex = parseInt(action.encoderId) - 1;
            const channelProfiles = { ...store.getState().config.channelProfile };
            channelProfiles[action.inputId].videoIFBSourceIdx = -1
            channelProfiles[action.inputId].videoIFBEncoderIdx = encoderIndex;
            Logger.info('[SocketMiddleware] Bind video IFB encoder to an input');
            socket.emit(SE_SET_PROFILE_CONFIG, 'channelProfile', false, JSON.stringify({'channelProfile' : channelProfiles }));
          }
        }
        else if(action.type === DASHBOARD_INPUT_VIDEO_IFB_SOURCE_BIND){
          if(action.inputId && action.sourceId){
            const sourceIndex = parseInt(action.sourceId) - 1;
            const channelProfiles = { ...store.getState().config.channelProfile };
            channelProfiles[action.inputId].videoIFBSourceIdx = sourceIndex;
            channelProfiles[action.inputId].videoIFBEncoderIdx = -1;
            Logger.info('[SocketMiddleware] Bind video IFB source to an input');
            socket.emit(SE_SET_PROFILE_CONFIG, 'channelProfile', false, JSON.stringify({'channelProfile' : channelProfiles }));
          }
        }
        else if(action.type === INTERCOM_OPEN){
          if(action.open){
            Logger.info('[SocketMiddleware] Opening intercom');
            socket.emit(SE_OPEN_INTERCOM);
          }
          else {
            Logger.info('[SocketMiddleware] Closing intercom');
            socket.emit(SE_CLOSE_INTERCOM);
          }
        }
        else if(action.type === DECRYPT_PASSWORD){
          Logger.info('[SocketMiddleware] Decrypt password', action.encryptedPassword);
          socket.emit(SE_DECRYPT_PASSWORD, action.adminPassword, action.encryptedPassword);
        }
        else if(action.type === DASHBOARD_INPUT_INTERCOM_AUDIO_PATTERN){
          const inputIdNumber = parseInt(action.inputId);
          if(!isNaN(inputIdNumber)){
            Logger.info('[SocketMiddleware] Starting/Stopping intercom audio pattern');
            if(action.active){
              socket.emit(SE_START_AUDIO_PATTERN, inputIdNumber);
            }
            else {
              socket.emit(SE_STOP_AUDIO_PATTERN, inputIdNumber);
            }
          }
        }
        else if(action.type === DASHBOARD_INPUT_RECONNECT_MODEM){
          Logger.info('[SocketMiddleware] Reconnect Modem: ', action.name, action.input.name);
          socket.emit('reconnectModem', action.name, action.input.port);
          //Logging
          socket.emit(SE_ADD_LOG, `Reconnect modem : ${action.name}, ${action.input.name}`);
        }
        else if(action.type === DASHBOARD_INPUT_DISCONNECT_MODEM){
          Logger.info('[SocketMiddleware] Disconnect Modem: ', action.name, action.input.name);
          socket.emit('disconnectModem', action.name, action.input.port);
          //Logging
          socket.emit(SE_ADD_LOG, `Disconnect modem : ${action.name}, ${action.input.name}`);
        }
        else if(action.type === DASHBOARD_INTERCOM_MUTE){
          Logger.info('[SocketMiddleware] Muting intercom');
          socket.emit('mute', action.channelType, action.inputId, action.mute);
        }
        else if(action.type === DASHBOARD_OUTPUT_START || action.type === DASHBOARD_OUTPUT_LOCK){
          const pendingForConfig = store.getState().streamhub.pendingForConfig;
          if (!pendingForConfig) {
            dispatchAction(emittingConfig());
            let enableOutputPayload = null;
            if(action.output.type === OUTPUT_TYPE_HARDWARE){
              enableOutputPayload = toggleHardwareOutputConfig(action.output.id, action.itemConfig, true);
            }
            else if(action.output.type === OUTPUT_TYPE_NDI){
              enableOutputPayload = toggleNDIOutputConfig(action.output.id, action.itemConfig, true);
            }
            else {
              enableOutputPayload = toggleIPOutputConfig(action.output.id, action.itemConfig, true); //Output 'id' property IS the profile ID
            }
            Logger.info('[SocketMiddleware] Emitting config: ', enableOutputPayload);
            socket.emit(SE_SET_ITEM_CONFIG, enableOutputPayload);
          }
        }
        else if(action.type === DASHBOARD_OUTPUT_STOP || action.type === DASHBOARD_OUTPUT_UNLOCK){
          const pendingForConfig = store.getState().streamhub.pendingForConfig;
          if (!pendingForConfig) {
            dispatchAction(emittingConfig());
            let disableOutputPayload = null;
            if(action.output.type === OUTPUT_TYPE_HARDWARE){
              disableOutputPayload = toggleHardwareOutputConfig(action.output.id, action.itemConfig, false);
            }
            else if(action.output.type === OUTPUT_TYPE_NDI){
              disableOutputPayload = toggleNDIOutputConfig(action.output.id, action.itemConfig, false);
            }
            else {
              disableOutputPayload = toggleIPOutputConfig(action.output.id, action.itemConfig, false); //Output 'id' property IS the profile ID
            }
            Logger.info('[SocketMiddleware] Emitting config: ', disableOutputPayload);
            socket.emit(SE_SET_ITEM_CONFIG, disableOutputPayload);
          }
        }
        else if(action.type === DASHBOARD_OUTPUT_ATTACH_PROFILE){
          const { profileId } = action;
          const streamingOutputForAttach = { ...store.getState().config.streamingOutput };
          const maxOrder = maxStreamingOutputOrder(streamingOutputForAttach);
          const newOrder = maxOrder + 1; // We always display newly attached profile at the end of IP Outputs
          streamingOutputForAttach[profileId].channelIndex=-1;
          streamingOutputForAttach[profileId].encoderIndex=-1;
          Logger.info("[SocketMiddleware] Streaming output max index: ", maxOrder);
          if(profileId){
            // We attach to an existing IP Output profile
            const oldConfig = streamingOutputForAttach[profileId];
            streamingOutputForAttach[profileId] = { ...oldConfig, outputOrder: newOrder, enable: false };
          }
          Logger.info('[SocketMiddleware] Updating streamingOutput: ', streamingOutputForAttach);
          socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', false, JSON.stringify({ streamingOutput: streamingOutputForAttach }));
        }
        else if(action.type === DASHBOARD_OUTPUT_DETACH_PROFILE){
          if(action.output.ipProfileId){
            const streamingOutputForAttach = { ...store.getState().config.streamingOutput };
            const oldConfig = streamingOutputForAttach[action.output.ipProfileId];
            oldConfig.outputOrder = -1;
            oldConfig.channelIndex = -1;
            oldConfig.encoderIndex = -1;
            streamingOutputForAttach[action.output.ipProfileId] = oldConfig;
            if(streamingOutputForAttach['undefined']){
              delete streamingOutputForAttach['undefined'];
            }
            Logger.info('[SocketMiddleware] Updating streamingOutput: ', streamingOutputForAttach);
            socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', false, JSON.stringify({ streamingOutput: streamingOutputForAttach }));
          }
        }
        else if(action.type === DASHBOARD_OUTPUT_ATTATCH_NDI_PROFILE){
          const NDIOutputForAttach = { ...store.getState().config.NDIOutput };
          const maxOrder = maxStreamingOutputOrder(NDIOutputForAttach) ;
          NDIOutputForAttach[maxOrder+1].channelIndex=-1;
          NDIOutputForAttach[maxOrder+1].encoderIndex=-1;
          Logger.info("[SocketMiddleware] Streaming output max index: ", maxOrder+1);
          // We attach to an existing IP Output profile
          const oldConfig = NDIOutputForAttach[maxOrder+1];
          NDIOutputForAttach[maxOrder+1] = { ...oldConfig, outputOrder: maxOrder+1, enable: false };
          Logger.info('[SocketMiddleware] Updating NDIOutput: ', NDIOutputForAttach);
          socket.emit(SE_SET_PROFILE_CONFIG, 'NDIOutput', false, JSON.stringify({ NDIOutput: NDIOutputForAttach }));
        }
        else if(action.type === DASHBOARD_OUTPUT_DETATCH_NDI_PROFILE){
          const NDIOutputForAttach = { ...store.getState().config.NDIOutput };
            Logger.info('[SocketMiddleware] Updating NDIOutput: ', NDIOutputForAttach);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig(`NDIOutput.${maxStreamingOutputOrder(NDIOutputForAttach)}.outputOrder`, -1));
        }
        else if(action.type === DASHBOARD_OUTPUT_ATTATCH_HARDWARE_PROFILE){
          const hardwareOutputForAttach = { ...store.getState().config.basebandPlayer };
          const maxOrder = maxStreamingOutputOrder(hardwareOutputForAttach) ;
          hardwareOutputForAttach[maxOrder+1].channelIndex=-1;
          hardwareOutputForAttach[maxOrder+1].encoderIndex=-1;
          Logger.info("[SocketMiddleware] Streaming output max index: ", maxOrder+1);
          // We attach to an existing IP Output profile
          const oldConfig = hardwareOutputForAttach[maxOrder+1];
          hardwareOutputForAttach[maxOrder+1] = { ...oldConfig, outputOrder: maxOrder+1, enable: false };
          Logger.info('[SocketMiddleware] Updating basebandPlayer: ', hardwareOutputForAttach);
          socket.emit(SE_SET_PROFILE_CONFIG, 'basebandPlayer', false, JSON.stringify({ basebandPlayer: hardwareOutputForAttach }));
          setTimeout(() => {
            socket.emit(SE_GET_OUTPUTS_STATUS);
          }, 300);
        }
        else if(action.type === DASHBOARD_OUTPUT_DETATCH_HARDWARE_PROFILE){
          const hardwareOutputForAttach = { ...store.getState().config.basebandPlayer };
            Logger.info('[SocketMiddleware] Updating basebandPlayer: ', hardwareOutputForAttach);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig(`basebandPlayer.${maxStreamingOutputOrder(hardwareOutputForAttach)}.outputOrder`, -1));
        }
        else if(action.type === DASHBOARD_OUTPUT_SWITCH_INPUT){
          let outputEnabled = action.output.status !== STATUS_OFF || action.forceStart === true;
          if(action.output.type === OUTPUT_TYPE_IP){
            const streamingOutputForInputSwitch = { ...store.getState().config.streamingOutput };
            if(action.inputId){
              if(isNaN(parseInt(action.inputId))){
                throw new Error(`Cannot compute input index from ${action.inputId}`);
              }
              streamingOutputForInputSwitch[action.output.ipProfileId].channelIndex = parseInt(action.inputId) - 1;
              streamingOutputForInputSwitch[action.output.ipProfileId].encoderIndex = -1;
            }
            else if(action.encoderId){
              if(isNaN(parseInt(action.encoderId))){
                throw new Error(`Cannot compute encoder index from ${action.encoderId}`);
              }
              const encoderIndex = parseInt(action.encoderId) - 1;
              streamingOutputForInputSwitch[action.output.ipProfileId].channelIndex = -1;
              streamingOutputForInputSwitch[action.output.ipProfileId].encoderIndex = encoderIndex;
            }
            streamingOutputForInputSwitch[action.output.ipProfileId].enable = outputEnabled;

            Logger.info('[SocketMiddleware] Updating streamingOutput: ', streamingOutputForInputSwitch);
            socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', false, JSON.stringify({ streamingOutput: streamingOutputForInputSwitch }));
          }
          else {
            if(action.output.type === OUTPUT_TYPE_HARDWARE && action.inputId){
              const inputIndex = parseInt(action.inputId) - 1;
              Logger.info(`[SocketMiddleware] Play stream on ${parseInt(action.output.id)} from ${inputIndex} => starting ? ${outputEnabled}`);
              socket.emit(SE_PLAY_STREAM, parseInt(action.output.id), inputIndex, outputEnabled ? 1 : 0);
            }
            else if(action.output.type === OUTPUT_TYPE_NDI && action.inputId){
              const inputIndex = parseInt(action.inputId) - 1;
              Logger.info(`[SocketMiddleware] Play NDI stream on ${parseInt(action.output.id)} from ${inputIndex} => starting ? ${outputEnabled}`);
              socket.emit(SE_PLAY_NDI_STREAM, parseInt(action.output.id), inputIndex, outputEnabled ? 1 : 0);
            }
          }
        }
        else if(action.type === DASHBOARD_OUTPUTS_PERSIST_POSITIONS){
          Logger.info(`[SocketMiddleware] Persisting IP Outputs positions`);
          const { config, streamhub } = store.getState();
          const indexesById = {};
          streamhub.ipOutputs.forEach((output) => {
            indexesById[output.ipProfileId] = output.index;
          });
          const mutableStreamingOutput = { ...config.streamingOutput };
          Object
            .keys(mutableStreamingOutput)
            .forEach(key => {
              let output = mutableStreamingOutput[key];
              if(indexesById[key] != null){
                output.outputOrder = indexesById[key];
              }
              mutableStreamingOutput[key] = output;
            });
          Logger.info('[SocketMiddleware] Updating streamingOutput: ', mutableStreamingOutput);
          socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', false, JSON.stringify({ streamingOutput: mutableStreamingOutput }));
        }
        else if(action.type === DASHBOARD_INPUT_EJECT_VIDEO_IFB){
          Logger.info(`[SocketMiddleware] Eject Video IFB for input ${action.input.id}`);
          socket.emit(SE_EJECT_VIDEO_IFB, {inputId : parseInt(action.input.id) });
          //Logging
          socket.emit(SE_ADD_LOG, `Eject Video IFB for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_REMOTE_LIVE_START){
          Logger.info(`[SocketMiddleware] Starting live for input with port ${action.input.port} with instance ${action.input.instanceId}`);
          socket.emit(SE_START_LIVE, action.input.port, action.input.instanceId);
          //Logging
          socket.emit(SE_ADD_LOG, `Starting live for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_REMOTE_LIVE_STOP){
          Logger.info(`[SocketMiddleware] Stopping live for input with port ${action.input.port} with instance ${action.input.instanceId}`);
          socket.emit(SE_STOP_LIVE, action.input.port, action.input.instanceId);
          //Logging
          socket.emit(SE_ADD_LOG, `Stopping live for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_REMOTE_FORWARD_STOP){
          Logger.info(`[SocketMiddleware] Stopping forward for input with port ${action.input.port}`);
          socket.emit(SE_STOP_FORWARD, action.input.port);
          //Logging
          socket.emit(SE_ADD_LOG, `Stopping forward for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_RECORD_START){
          Logger.info(`[SocketMiddleware] Starting record for input ${action.input.id}`);
          socket.emit(SE_UNIT_COMMAND, 'startRecorder', parseInt(action.input.id), action.input.recorderPrefix ? action.input.recorderPrefix : '');
        }
        else if(action.type === DASHBOARD_INPUT_RECORD_STOP){
          Logger.info(`[SocketMiddleware] Stopping record for input ${action.input.id}`);
          socket.emit(SE_UNIT_COMMAND, 'stopRecorder', parseInt(action.input.id));
        }
        else if(action.type === DASHBOARD_INPUT_INTERCOM_START){
          Logger.info(`[SocketMiddleware] Starting intercom for input ${action.input.id}`);
          socket.emit(SE_UNIT_COMMAND, 'startIntercom', parseInt(action.input.id));
          //Logging
          socket.emit(SE_ADD_LOG, `Starting intercom for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_INTERCOM_STOP){
          Logger.info(`[SocketMiddleware] Stopping intercom for input ${action.input.id}`);
          socket.emit(SE_UNIT_COMMAND, 'stopIntercom', parseInt(action.input.id));
          //Logging
          socket.emit(SE_ADD_LOG, `Stopping intercom for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_REMOTE_PROFILE_SWITCH){
          Logger.info(`[SocketMiddleware] Switching input ${action.input.id} with instanceId ${action.input.instanceId} to live profile ${action.profileId}`);
          socket.emit(SE_LIVE_PROFILE, action.profileId, action.input.port, action.input.instanceId);
          //Logging
          socket.emit(SE_ADD_LOG, `Changing live profile for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_SUBSCRIBE){
          const { input } = action;
          Logger.info(`[SocketMiddleware] Subscribing to input ${action.input.id} details`);
          socket.emit(SE_CHANNEL_INIT, parseInt(input.id), input.port, input.deviceProduct);
        }
        else if(action.type === DASHBOARD_INPUT_UNSUBSCRIBE){
          Logger.info(`[SocketMiddleware] Unsubscribing to input ${action.input.id} details`);
          socket.emit(SE_CHANNEL_CLOSE, parseInt(action.input.port));
        }
        else if(action.type === DASHBOARD_INPUT_CHANGE_VIDEO_CAPPED_BITRATE){
          const { input, videoCappedBitrate } = action;
          Logger.info(`[SocketMiddleware] Changing video capped bitrate for input ${input.id}`);
          socket.emit(SE_SET_VIDEO_CAPPED_BITRATE, videoCappedBitrate, input.port, input.instanceId);
          //Logging
          socket.emit(SE_ADD_LOG,`Changing video capped bitrate for input ${input.id} to ${videoCappedBitrate}`);
        }
        else if(action.type === DASHBOARD_INPUT_CHANGE_LATENCY){
          const { input, latency } = action;
          Logger.info(`[SocketMiddleware] Changing delay for input ${input.id}`, latency);
          socket.emit(SE_SET_LATENCY, latency, input.port, input.instanceId);
          //Logging
          socket.emit(SE_ADD_LOG, `Changing latency for input ${input.id} to ${latency}`);
        }
        else if(action.type === DASHBOARD_INPUT_RESET_DROPPED_PACKETS){
          const { input } = action;
          Logger.info(`[SocketMiddleware] Resetting dropped packets for input ${input.id}`);
          socket.emit(SE_RESET_PACKET_LOST, parseInt(input.id));
        }
        else if(action.type === DASHBOARD_ENCODER_START){
          const pendingForConfig = store.getState().streamhub.pendingForConfig;
          if (!pendingForConfig) {
            dispatchAction(emittingConfig());
            const { encoder } = action;
            const encoderConfig = { ...store.getState().config.enc };
            encoderConfig[encoder.id].enable = true;
            Logger.info('[SocketMiddleware] Updating encoder config: ', encoderConfig);
            socket.emit(SE_SET_PROFILE_CONFIG, 'enc', false, JSON.stringify({'enc' : encoderConfig }));
          }
        }
        else if(action.type === DASHBOARD_ENCODER_STOP){
          const pendingForConfig = store.getState().streamhub.pendingForConfig;
          if (!pendingForConfig) {
            dispatchAction(emittingConfig());
            const { encoder } = action;
            const encoderConfig = { ...store.getState().config.enc };
            encoderConfig[encoder.id].enable = false;
            Logger.info('[SocketMiddleware] Updating encoder config: ', encoderConfig);
            socket.emit(SE_SET_PROFILE_CONFIG, 'enc', false, JSON.stringify({'enc' : encoderConfig }));
          }
        }
        else if(action.type === DASHBOARD_ENCODER_ATTACH_PROFILE){
          const { encoder, profileId } = action;
          if(isNaN(parseInt(profileId))){
            throw new Error("Cannot compute encoder profile index");
          }
          const encoderConfig = { ...store.getState().config.enc };
          encoderConfig[encoder.id].encoderProfileId = parseInt(profileId);
          if(encoderConfig[encoder.id].encoderProfileId!==-1){
            encoderConfig[encoder.id].inputIndex = -1;
          }
          Logger.info('[SocketMiddleware] Updating encoder config: ', encoderConfig);
          socket.emit(SE_SET_PROFILE_CONFIG, 'enc', false, JSON.stringify({'enc' : encoderConfig }));
        }
        else if(action.type === DASHBOARD_ENCODER_DETACH_PROFILE){
          const { encoder } = action;
          const encoderConfig = { ...store.getState().config.enc };

          // Check videoIFBEncoderIdx in config for inputs
          let setProfileConfigNeeded = false
          const channelProfiles = { ...store.getState().config.channelProfile };
          Object.keys(channelProfiles).forEach((key) => {
            if (channelProfiles[key].videoIFBEncoderIdx === parseInt(encoder.id) - 1 ) {
              channelProfiles[key].videoIFBEncoderIdx = -1
              setProfileConfigNeeded = true
            }
          })
          if (setProfileConfigNeeded) {
            Logger.info('[SocketMiddleware] Updating channelProfiles: ', channelProfiles);
            socket.emit(SE_SET_PROFILE_CONFIG, 'channelProfile', false, JSON.stringify({'channelProfile' : channelProfiles }));
          }

          // Check encoderIndex in config for outputs
          setProfileConfigNeeded = false
          const streamingOutputs = { ...store.getState().config.streamingOutput };
          Object.keys(streamingOutputs).forEach((key) => {
            if (streamingOutputs[key].encoderIndex === parseInt(encoder.id) - 1 ) {
              streamingOutputs[key].encoderIndex = -1
              streamingOutputs[key].enable = false
              setProfileConfigNeeded = true
            }
          })
          if (setProfileConfigNeeded) {
            Logger.info('[SocketMiddleware] Updating streamingOutput: ', streamingOutputs);
            socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', false, JSON.stringify({ streamingOutput: streamingOutputs }));
          }

          // Update encoder config
          encoderConfig[encoder.id].encoderProfileId = -1;
          Logger.info('[SocketMiddleware] Updating encoder config: ', encoderConfig);
          socket.emit(SE_SET_PROFILE_CONFIG, 'enc', false, JSON.stringify({'enc' : encoderConfig }));
        }
        else if(action.type === DASHBOARD_ENCODER_SWITCH_INPUT){
          const { encoder, inputId, forceStart } = action;
          if(isNaN(parseInt(inputId))){
            throw new Error("Cannot compute encoder profile index");
          }
          const encoderConfig = { ...store.getState().config.enc };
          encoderConfig[encoder.id].inputIndex = parseInt(inputId) - 1;
          if(forceStart){
            encoderConfig[encoder.id].enable = true;
          }
          Logger.info('[SocketMiddleware] Updating encoder config: ', encoderConfig);
          socket.emit(SE_SET_PROFILE_CONFIG, 'enc', false, JSON.stringify({'enc' : encoderConfig }));
        }
        else if(action.type === DASHBOARD_ENCODER_RECORD_START){
          Logger.info(`[SocketMiddleware] Starting record for encoder ${action.encoder.id}`);
          socket.emit(SE_RECODERENC, 'startRecorder', parseInt(action.encoder.id), action.encoder.recorderPrefix ? action.encoder.recorderPrefix : '');
        }
        else if(action.type === DASHBOARD_ENCODER_RECORD_STOP){
          Logger.info(`[SocketMiddleware] Stopping record for encoder ${action.encoder.id}`);
          socket.emit(SE_RECODERENC, 'stopRecorder', parseInt(action.encoder.id));
        }
        else if(action.type === SETTINGS_GENERAL_UPDATE){
          const config = { ...store.getState().config };
          const data = action.data;

          //Identifier
          if(config.device.Identifier !== data.identifier){
            Logger.info('[SocketMiddleware] Updating general settings - Identifier', data.identifier);
            socket.emit(SE_SET_ITEM_CONFIG, textItemConfig("device.Identifier", data.identifier));
          }
          //Language
          if(config.device.Language !== data.language){
            Logger.info('[SocketMiddleware] Updating general settings - Language', data.language);
            socket.emit(SE_SET_ITEM_CONFIG, textItemConfig("device.Language", data.language));
          }
          //controlEnd2End
          if(config.other.enableControlEnd2End !== data.enableControlEnd2End){
            Logger.info('[SocketMiddleware] Updating general settings - Enable Contole End2End', data.enableControlEnd2End);
            socket.emit(SE_SET_ITEM_CONFIG, booleanItemConfig("other.enableControlEnd2End", data.enableControlEnd2End));
          }
        }
        else if(action.type === SETTINGS_GENERAL_EXPORT_CONFIG){
          Logger.info('[SocketMiddleware] Exporting config');
          socket.emit(SE_EXPORT_CONFIG);
        }
        else if(action.type === SETTINGS_GENERAL_IMPORT_CONFIG){
          const { file } = action;
          Logger.info('[SocketMiddleware] Importing config:', file);
          const reader = new FileReader();
          reader.onload = (loadEvent) => {
            socket.emit(SE_IMPORT_CONFIG, loadEvent.target.result, file.name);
          };
          //Log
          socket.emit(SE_ADD_LOG, 'Config Imported')
          // Load a block of selected file
          reader.readAsDataURL(file);
        }
        else if(action.type === SETTINGS_GENERAL_RESET_CONFIG){
          Logger.info('[SocketMiddleware] Resetting factory config');
          socket.emit(SE_FACTORY_RESET_CONFIG);
        }
        else if(action.type === SETTINGS_GENERAL_EXPORT_STREAMHUB_PROFILE){
          Logger.info('[SocketMiddleware] Connection profile: ', action.profile);
          socket.emit(SE_GET_STREAMHUB_PROFILE, JSON.stringify(action.profile));
        }
        else if(action.type === SETTINGS_SECURITY_UPDATE){
          const config = { ...store.getState().config };
          const data = action.data;
          if(config.authentificationRetryMax !== data.authentificationRetryMax){
            Logger.info('[SocketMiddleware] Updating security settings - authentificationRetryMax', data.authentificationRetryMax);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig("authentificationRetryMax", data.authentificationRetryMax));
          }
          // SSL Certificate
          if (data.sslCertificate && data.sslPrivateKey) {
            Logger.info('[SocketMiddleware] Updating security settings - SSL Certificate', data.sslCertificate, data.sslPrivateKey);
            socket.emit('installSslCertificate', data.sslCertificate, data.sslPrivateKey);
          }
        }
        else if(action.type === SETTINGS_LDAP_UPDATE){
          const data = action.data;
          Logger.info('[SocketMiddleware] Updating LDAP settings', data);
          socket.emit(SE_SET_OBJECT_CONFIG_WITH_PASSWORD, 'ldap', data);
        }
        else if(action.type === SETTINGS_NETWORK_UPDATE){
          const config = { ...store.getState().config };
          const data = action.data;

          //AESkey
          if(data.aesKey && config.AESkey !== data.aesKey){
            Logger.info('[SocketMiddleware] Updating network settings - AESkey', data.aesKey);
            socket.emit(SE_SET_ITEM_CONFIG, textItemConfig("AESkey", data.aesKey, true));
          }
          //AESkeyEnable
          if(config.AESkeyEnable !== data.AESkeyEnable){
            Logger.info('[SocketMiddleware] Updating network settings - AESkeyEnable', data.AESkeyEnable);
            socket.emit(SE_SET_ITEM_CONFIG, booleanItemConfig("AESkeyEnable", data.AESkeyEnable));
          }
          //Connection
          if(config.ebonding.atpGeneralBasePort !== data.atpGeneralBasePort || config.ebonding.username !== data.username || config.ebonding.password !== data.password){
            const updatedEbonding = {
              ...config.ebonding,
              atpGeneralBasePort: data.atpGeneralBasePort,
              username: data.username,
              password: data.password
            };
            Logger.info('[SocketMiddleware] Updating network settings - ebonding', updatedEbonding);
            socket.emit(SE_SET_OBJECT_CONFIG_WITH_PASSWORD, 'ebonding', updatedEbonding);
          }
          //Tun
          if(config.tun.baseTunIPAddr !== data.baseTunIPAddr){
            const updatedTun = {
              ...config.tun,
              baseTunIPAddr: data.baseTunIPAddr
            };
            Logger.info('[SocketMiddleware] Updating network settings - tun', updatedTun);
            socket.emit(SE_SET_OBJECT_CONFIG, 'tun', JSON.stringify(updatedTun));
          }
          //tunRemoteControl.webProxyPort & tunRemoteControl.webProxyPortHttps
          if(config.tunRemoteControl.webProxyPort !== data.webProxyPort || config.tunRemoteControl.webProxyPortHttps !== data.webProxyPortHttps){
            const updatedTunRemoteControl = {
              ...config.tunRemoteControl,
              webProxyPort: data.webProxyPort,
              webProxyPortHttps: data.webProxyPortHttps
            };
            Logger.info('[SocketMiddleware] Updating network settings - tunRemoteControl', updatedTunRemoteControl);
            socket.emit(SE_SET_OBJECT_CONFIG, 'tunRemoteControl', JSON.stringify(updatedTunRemoteControl));
          }
          //Abus proxy tcp port
          if(config['abus-proxy'].tcpPort !== data.abusProxyTcpPort){
            Logger.info('[SocketMiddleware] Updating network settings - abus-proxy', data.abusProxyTcpPort);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig("abus-proxy.tcpPort", data.abusProxyTcpPort));
          }

          Logger.info('[SocketMiddleware] Updating network settings config needs restart');
          socket.emit(SE_RESTART_STREAMHUB);
        }
        else if(action.type === SETTINGS_NDI_UPDATE){
          const data = {
            multicast: {
              enable: action.data.enable,
              netmask: action.data.netmask,
              netprefix: action.data.netprefix
            },
            ndiMessagingServers: action.data.ndiMessagingServers
          };
          Logger.info('[SocketMiddleware] Updating NDI settings', data);
          socket.emit(SE_SET_OBJECT_CONFIG, 'ndi', JSON.stringify(data));

          Logger.info('[SocketMiddleware] Updating NDI Multicast settings config needs restart');
          socket.emit(SE_RESTART_STREAMHUB);
        }
        else if(action.type === SETTINGS_NETWORK_ADVANCED_UPDATE){
          const config = { ...store.getState().config };
          const data = action.data;
          let needRestart=false;
          // Hostname
          if(config.hostname !== data.hostname){
            Logger.info('[SocketMiddleware] Updating advanced network settings - Hostname', data.hostname);
            socket.emit(SE_SET_ITEM_CONFIG, textItemConfig("hostname", data.hostname, false));
          }
          if(config.rtspserver.port !== data.rtspserverPort){
            needRestart = true
            Logger.info('[SocketMiddleware] Updating advanced network settings - RTSP server port', data.rtspserverPort);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig("rtspserver.port", data.rtspserverPort, false));
          }
          if(config["guest-interview"].udpPort !== data.udpPort){
            needRestart = true
            Logger.info('[SocketMiddleware] Updating advanced network settings - Liveguest UDP port', data.udpPort);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig("guest-interview.udpPort", data.udpPort, false));
          }
          if(config["guest-interview"].webrtcBasePort !== data.webrtcBasePort){
            needRestart = true
            Logger.info('[SocketMiddleware] Updating advanced network settings -  Liveguest webRTC base port', data.webrtcBasePort);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig("guest-interview.webrtcBasePort", data.webrtcBasePort, false));
          }
          if(config["guest-interview"].webrtcRange !== data.webrtcRange){
            needRestart = true
            Logger.info('[SocketMiddleware] Updating advanced network settings - Liveguest webRTC range', data.webrtcRange);
            socket.emit(SE_SET_ITEM_CONFIG, numberItemConfig("guest-interview.webrtcRange", data.webrtcRange, false));
          }
          if(needRestart){
            Logger.info('[SocketMiddleware] Updating network config needs restart');
            socket.emit(SE_RESTART_STREAMHUB);
          }
        }
        else if(action.type === SETTINGS_MEDIA_RECEIVED_FILES_UPDATE){
          const config = { ...store.getState().config };
          const data = {
            ...config.fileTranscoder,
            receivedFile: action.config
          };
          Logger.info('[SocketMiddleware] Updating media settings - fileTranscoder', data);
          socket.emit(SE_SET_OBJECT_CONFIG, 'fileTranscoder', JSON.stringify(data));

        }
        else if(action.type === SETTINGS_MEDIA_RECORDED_FILES_UPDATE){
          const config = { ...store.getState().config };
          const data = {
            ...config.fileTranscoder,
            recordedFile: action.config
          };
          Logger.info('[SocketMiddleware] Updating media settings - fileTranscoder', data);
          socket.emit(SE_SET_OBJECT_CONFIG, 'fileTranscoder', JSON.stringify(data));
        }
        else if(action.type === SETTINGS_MEDIA_RENAME_FILES_UPDATE){
          const config = { ...store.getState().config };
          const data = {
            ...config.fileTranscoder,
            renameFile: action.config
          };
          Logger.info('[SocketMiddleware] Updating media settings - fileTranscoder', data);
          socket.emit(SE_SET_OBJECT_CONFIG, 'fileTranscoder', JSON.stringify(data));
        }
        else if(action.type === SETTINGS_MEDIA_FILE_CLEANER_UPDATE){
          const { config } = action;
          Logger.info('[SocketMiddleware] Updating media settings - mediaCleaner', config);
          socket.emit(SE_SET_OBJECT_CONFIG, 'mediaCleaner', JSON.stringify(config));
        }
        else if(action.type === SETTINGS_INPUT_SAVE){
          if(action.inputId){
            const channelProfiles = { ...store.getState().config.channelProfile };
            channelProfiles[action.inputId].name = action.name;
            channelProfiles[action.inputId].autoRecord = action.autoRecord;
            channelProfiles[action.inputId].autoPlay = action.autoPlay;
            channelProfiles[action.inputId].recorderPrefix = action.recorderPrefix;
            channelProfiles[action.inputId].lostStreamTimeout = action.lostStreamTimeout;
            channelProfiles[action.inputId].videoIFBSourceIdx = action.videoIFBSourceIdx;
            channelProfiles[action.inputId].videoIFBEncoderIdx = action.videoIFBEncoderIdx;
            channelProfiles[action.inputId].videoIFBLatency = action.videoIFBLatency;
            Logger.info('[SocketMiddleware] Updating channelProfiles: ', channelProfiles);
            socket.emit(SE_SET_PROFILE_CONFIG, 'channelProfile',false, JSON.stringify({'channelProfile' : channelProfiles }));

            // Intercom
            const intercomProfiles = { ...store.getState().config.intercomChannelProfile };
            intercomProfiles[action.inputId] = { ...intercomProfiles[action.inputId], ...action.intercom };
            Logger.info('[SocketMiddleware] Updating intercom channel profile: ', intercomProfiles);
            socket.emit(SE_SET_OBJECT_CONFIG, 'intercomChannelProfile', JSON.stringify(intercomProfiles));
          }
        }
        else if(action.type === SETTINGS_INPUT_IP_PROFILE_CREATE){
          const inputProtocols = { ...store.getState().config.inputProtocol };
          let channelProfileId = -1;
          if(action.profile.channelProfileId) {
            // Reset previous channelProfileId
            Object.keys(inputProtocols).forEach((protocol) => {
              if (inputProtocols[protocol].channelProfileId === action.profile.channelProfileId) {
                inputProtocols[protocol].channelProfileId = -1
                inputProtocols[protocol].enable = false
              }
            })
            channelProfileId = action.profile.channelProfileId;
          }
          inputProtocols[`${action.profile.id}`] = { ...action.profile, enable: false , channelProfileId };
          Logger.info('[SocketMiddleware] Updating inputProtocol: ', inputProtocols);
          socket.emit(SE_SET_PROFILE_CONFIG, 'inputProtocol',true, JSON.stringify({'inputProtocol' : inputProtocols }));
        }
        else if(action.type === SETTINGS_INPUT_IP_PROFILE_UPDATE){
          const inputProtocols = { ...store.getState().config.inputProtocol };
          if(action.profileId && inputProtocols[action.profileId]){
            inputProtocols[action.profileId] = { ...inputProtocols[action.profileId], ...action.profile, enable: false };
            let pwdChanged = !(store.getState().config.inputProtocol[action.profileId].password === inputProtocols[action.profileId].password);
            Logger.info('[SocketMiddleware] Updating inputProtocol: ', inputProtocols);
            socket.emit(SE_SET_PROFILE_CONFIG, 'inputProtocol', pwdChanged, JSON.stringify({'inputProtocol' : inputProtocols }));
          }
        }
        else if(action.type === SETTINGS_INPUT_IP_PROFILE_DELETE){
          let inputProtocols = { ...store.getState().config.inputProtocol };
          if(action.profileId && inputProtocols[action.profileId]){
            const profile = { ...inputProtocols[action.profileId] };
            if(profile.type === WEBRTC){
              socket.emit(SE_DELETE_INPUT_PROFILE, profile.name);
            } else {
              delete inputProtocols[action.profileId];
              inputProtocols = reindexProfileConfigList(inputProtocols, 1)
              Logger.info('[SocketMiddleware] Deleting inputProtocol: ', inputProtocols);
              socket.emit(SE_SET_PROFILE_CONFIG, 'inputProtocol',false, JSON.stringify({'inputProtocol' : inputProtocols }));
            }
          }
        }
        else if(action.type === SETTINGS_INPUT_TMP_PROFILE_CREATE){
          const tmpProfile = { ...action.profile, enable: false };
          Logger.info('[SocketMiddleware] Creating TMP Profile: ', tmpProfile);
          socket.emit(SE_INPUT_TMP_PROFILE, tmpProfile);
        }
        else if(action.type === SETTINGS_OUTPUT_HARDWARE_SAVE){
          const basebandPlayers = { ...store.getState().config.basebandPlayer };
          const { type, ...otherProps } = action;
          basebandPlayers[action.outputId] = { ...basebandPlayers[action.outputId], ...otherProps };
          Logger.info('[SocketMiddleware] Updating basebandPlayer: ', basebandPlayers);
          socket.emit(SE_SET_PROFILE_CONFIG, 'basebandPlayer', false, JSON.stringify({'basebandPlayer' : basebandPlayers }));
        }
        else if(action.type === SETTINGS_OUTPUT_NDI_SAVE){
          const ndiOutputs = { ...store.getState().config.NDIOutput };
          const { type, ...otherProps } = action;
          ndiOutputs[action.outputId] = { ...ndiOutputs[action.outputId], ...otherProps };
          Logger.info('[SocketMiddleware] Updating NDIOutput: ', ndiOutputs);
          socket.emit(SE_SET_PROFILE_CONFIG, 'NDIOutput', false, JSON.stringify({'NDIOutput' : ndiOutputs }));
        }
        else if(action.type === SETTINGS_OUTPUT_IP_PROFILE_CREATE){
          const streamingOutputs = { ...store.getState().config.streamingOutput };
          streamingOutputs[`${nextIndexForProfileConfig(streamingOutputs)}`] = { ...action.profile, enable: false, lockstate: false, channelIndex: -1, outputOrder: -1 };
          Logger.info('[SocketMiddleware] Updating streaming output: ', streamingOutputs);
          if(action.profile.mode === 'SST' && action.profile.AESkey !== ''){
            socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', true, JSON.stringify({'streamingOutput' : streamingOutputs }));
            socket.emit(SE_SET_ITEM_CONFIG, textItemConfig(`streamingOutput.${nextIndexForProfileConfig(streamingOutputs)-1}.AESkey`, action.profile.AESkey, true));
          }else{
            socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', true, JSON.stringify({'streamingOutput' : streamingOutputs }));
          }
        }
        else if(action.type === SETTINGS_OUTPUT_IP_PROFILE_UPDATE){
          const streamingOutputs = { ...store.getState().config.streamingOutput };
          if(action.profileId && streamingOutputs[action.profileId]){
            streamingOutputs[action.profileId] = { ...streamingOutputs[action.profileId], ...action.profile, enable: false };
            let pwdChanged = !(store.getState().config.streamingOutput[action.profileId].password === streamingOutputs[action.profileId].password);
            Logger.info('[SocketMiddleware] Updating streaming output: ', streamingOutputs, streamingOutputs[action.profileId]);
            if(action.profile.mode === 'SST' && action.profile.AESkey !== store.getState().config.streamingOutput[action.profileId].AESkey && action.profile.AESkey !== ''){
              streamingOutputs[action.profileId].AESkey = store.getState().config.streamingOutput[action.profileId].AESkey ? store.getState().config.streamingOutput[action.profileId].AESkey : '';
              socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', pwdChanged, JSON.stringify({'streamingOutput' : streamingOutputs }));
              socket.emit(SE_SET_ITEM_CONFIG, textItemConfig(`streamingOutput.${action.profileId}.AESkey`, action.profile.AESkey, true));
            }else{
              socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', pwdChanged, JSON.stringify({'streamingOutput' : streamingOutputs }));
            }
            
          }
        }
        else if(action.type === SETTINGS_OUTPUT_IP_PROFILE_DELETE){
          let streamingOutputs = { ...store.getState().config.streamingOutput };
          if(action.profileId && streamingOutputs[action.profileId]){
            delete streamingOutputs[action.profileId];
            streamingOutputs = reindexProfileConfigList(streamingOutputs, 1)
            Logger.info('[SocketMiddleware] Deleting streaming output: ', streamingOutputs);
            socket.emit(SE_SET_PROFILE_CONFIG, 'streamingOutput', false, JSON.stringify({'streamingOutput' : streamingOutputs }));
          }
        }
        else if(action.type === SETTINGS_GET_NDI_STREAMS){
          Logger.info('[SocketMiddleware] Get NDI Streams');

          socket.emit(SE_GET_NDI_STREAMS);
        }
        else if(action.type === SETTINGS_PREP_NDI_FINDER){
          Logger.info('[SocketMiddleware] Prep NDI Finder');

          socket.emit(SE_PREP_NDI_FINDER, {groups: action.groups, extraIPs: action.extraIPs});
        }
        else if(action.type === SETTINGS_CUSTOM_PATTERN_UPDATE){
          Logger.info('[SocketMiddleware] Uploading pattern: ', action.prefix, action.id, action.file);
          dispatchAction(appendBannerMessage(BANNER_MESSAGE_UPLOAD_IN_PROGRESS, { key: 'data' }));
          socket.emit(SE_UPLOAD_USER_FILE, action.file, action.prefix, action.id);
        }
        else if(action.type === DASHBOARD_INPUT_PLAYBACK_START){
          Logger.info('[SocketMiddleware] Starting playback: ', action.input, action.file);
          socket.emit(SE_PLAYBACK, SE_PLAYBACK_START, { inputId: parseInt(action.input.id), file: action.file.path });
          //Logging
          socket.emit(SE_ADD_LOG, `Starting playback for input ${action.input.id} with ${action.file.filename}`);
        }
        else if(action.type === DASHBOARD_INPUT_PLAYBACK_PLAY){
          Logger.info('[SocketMiddleware] Playing playback: ', action.input);
          socket.emit(SE_PLAYBACK, SE_PLAYBACK_RESUME, { inputId: parseInt(action.input.id) });
          //Logging
          socket.emit(SE_ADD_LOG, `Playing playback for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_PLAYBACK_PAUSE){
          Logger.info('[SocketMiddleware] Pausing playback: ', action.input);
          socket.emit(SE_PLAYBACK, SE_PLAYBACK_PAUSE, { inputId: parseInt(action.input.id) });
          //Logging
          socket.emit(SE_ADD_LOG, `Pausing playback for input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_PLAYBACK_SEEK){
          Logger.info('[SocketMiddleware] Seeking playback: ', action.input, action.seeking);
          socket.emit(SE_PLAYBACK, SE_PLAYBACK_SEEK, { inputId: parseInt(action.input.id), position: action.seeking });
          //Logging
          socket.emit(SE_ADD_LOG, `Seeking playback for input ${action.input.id} at ${action.seeking}s`);
        }
        else if(action.type === DASHBOARD_INPUT_PLAYBACK_STOP){
          Logger.info('[SocketMiddleware] Stopping playback: ', action.input);
          socket.emit(SE_PLAYBACK, SE_PLAYBACK_STOP, { inputId: parseInt(action.input.id) });
          //Logging
          socket.emit(SE_ADD_LOG, `Ejecting playback file from input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_PLAYBACK_LOOP_ON){
          Logger.info('[SocketMiddleware] Starting playback loop: ', action.input);
          socket.emit(SE_PLAYBACK, SE_PLAYBACK_LOOP, { inputId: parseInt(action.input.id), isPlaybackLooping: true});
          //Logging
          socket.emit(SE_ADD_LOG, `Starting playback loopfrom input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_INPUT_PLAYBACK_LOOP_OFF){
          Logger.info('[SocketMiddleware] Stopping playback loop: ', action.input);
          socket.emit(SE_PLAYBACK, SE_PLAYBACK_LOOP, { inputId: parseInt(action.input.id), isPlaybackLooping: false });
          //Logging
          socket.emit(SE_ADD_LOG, `Stopping playback loopfrom input ${action.input.id}`);
        }
        else if(action.type === DASHBOARD_MULTIVIEW_START){
          const mosaic = {
            ...store.getState().config.mosaic,
            enable: true
          };
          Logger.info('[SocketMiddleware] Updating mosaic config: ', mosaic);
          socket.emit(SE_SET_OBJECT_CONFIG, 'mosaic', JSON.stringify(mosaic));
        }
        else if(action.type === DASHBOARD_MULTIVIEW_STOP){
          const mosaic = {
            ...store.getState().config.mosaic,
            enable: false
          };
          Logger.info('[SocketMiddleware] Updating mosaic config: ', mosaic);
          socket.emit(SE_SET_OBJECT_CONFIG, 'mosaic', JSON.stringify(mosaic));
        }
        else if(action.type === SETTINGS_MULTIVIEW_SAVE || action.type === DASHBOARD_MULTIVIEW_CHANGE_GRID || action.type === DASHBOARD_MULTIVIEW_SHOW_OVERLAY) {
          const { type, ...otherProps } = action;
          const mosaic = {
            ...store.getState().config.mosaic,
            ...otherProps
          };
          Logger.info('[SocketMiddleware] Updating mosaic config: ', mosaic);
          socket.emit(SE_SET_OBJECT_CONFIG, 'mosaic', JSON.stringify(mosaic));
        }
        else if(action.type === DASHBOARD_MULTIVIEW_SWITCH_AUDIO) {
          const mosaic = {
            ...store.getState().config.mosaic,
            audioChannelIndex: parseInt(action.inputId)
          };
          Logger.info('[SocketMiddleware] Updating mosaic config: ', mosaic);
          socket.emit(SE_SET_OBJECT_CONFIG, 'mosaic', JSON.stringify(mosaic));
        }
        else if(action.type === SETTINGS_SECURITY_UNBLOCK_ACCOUNTS){
          Logger.info('[SocketMiddleware] Unblocking accounts');
          socket.emit(SE_ACCOUNTS_UNBLOCK);
        }
        else if(action.type === SETTINGS_FILE_TRANSFER_TEST){
          //const fileTransfer = store.getState().config.fileTransfer[action.fileTransferId];
          const fileTransfer = action.fileTransfer;
          if(fileTransfer){
            Logger.info('[SocketMiddleware] Testing file transfer: ', fileTransfer);
            socket.emit(SE_FTP_TEST, fileTransfer);
          }
        }
        else if(action.type === SETTINGS_FILE_TRANSFER_CREATE){
          const fileTransferConfig = { ...store.getState().config.fileTransfer };
          fileTransferConfig[`${nextIndexForProfileConfig(fileTransferConfig)}`] = { ...action.fileTransfer };
          Logger.info('[SocketMiddleware] Updating fileTransfer config: ', fileTransferConfig);
          socket.emit(SE_SET_PROFILE_CONFIG, 'fileTransfer', false, JSON.stringify({'fileTransfer' : fileTransferConfig }));
        }
        else if(action.type === SETTINGS_FILE_TRANSFER_UPDATE){
          const fileTransferConfig = { ...store.getState().config.fileTransfer };
          if(action.fileTransfer.id && fileTransferConfig[action.fileTransfer.id]){
            const { id, ...otherProps } = action.fileTransfer;
            fileTransferConfig[id] = otherProps;
            Logger.info('[SocketMiddleware] Updating fileTransfer config: ', fileTransferConfig);
            socket.emit(SE_SET_PROFILE_CONFIG, 'fileTransfer', false, JSON.stringify({'fileTransfer' : fileTransferConfig }));
          }
        }
        else if(action.type === SETTINGS_FILE_TRANSFER_DELETE){
          let fileTransfer = { ...store.getState().config.fileTransfer };
          if(action.fileTransferId && fileTransfer[action.fileTransferId]){
            delete fileTransfer[action.fileTransferId];
            fileTransfer = reindexProfileConfigList(fileTransfer, 1)
            Logger.info('[SocketMiddleware] Updating fileTransfer config: ', fileTransfer);
            socket.emit(SE_SET_PROFILE_CONFIG, 'fileTransfer', false, JSON.stringify({'fileTransfer' : fileTransfer }));
          }
        }
        else if(action.type === SETTINGS_ENCODER_PROFILE_CREATE){
          const encoderProfileConfig = { ...store.getState().config.encoderProfile };
          encoderProfileConfig[`${nextIndexForProfileConfig(encoderProfileConfig)}`] = { ...action.profile };
          Logger.info('[SocketMiddleware] Updating encoderProfile config: ', encoderProfileConfig);
          socket.emit(SE_SET_PROFILE_CONFIG, 'encoderProfile', false, JSON.stringify({'encoderProfile' : encoderProfileConfig }));
        }
        else if(action.type === SETTINGS_ENCODER_PROFILE_UPDATE){
          const encoderProfileConfig = { ...store.getState().config.encoderProfile };
          const { profileId, profile } = action;
          if(profileId && encoderProfileConfig[profileId]){
            encoderProfileConfig[profileId] = profile;
            Logger.info('[SocketMiddleware] Updating encoderProfile config: ', encoderProfileConfig);
            socket.emit(SE_SET_PROFILE_CONFIG, 'encoderProfile', false, JSON.stringify({'encoderProfile' : encoderProfileConfig }));
          }
        }
        else if(action.type === SETTINGS_ENCODER_PROFILE_DELETE){
          let encoderProfileConfig = { ...store.getState().config.encoderProfile };
          const encoderConfig = { ...store.getState().config.enc };
          const profileId = action.profileId;
          if(profileId && encoderProfileConfig[profileId]){
            delete encoderProfileConfig[profileId];
            encoderProfileConfig = reindexProfileConfigList(encoderProfileConfig, 1)
            Object.keys(encoderConfig).forEach((enc) => {
              // Remove link to deleted encoder profile
              if (encoderConfig[enc].encoderProfileId === parseInt(profileId)) {
                encoderConfig[enc].encoderProfileId = -1
              }
              // Update all links to reindexed encoder profile if needed
              if (encoderConfig[enc].encoderProfileId > parseInt(profileId)) {
                encoderConfig[enc].encoderProfileId--
              }
            })
            Logger.info('[SocketMiddleware] Updating encoderProfile config: ', encoderProfileConfig);
            socket.emit(SE_SET_PROFILE_CONFIG, 'encoderProfile', false, JSON.stringify({'encoderProfile' : encoderProfileConfig }));
            Logger.info('[SocketMiddleware] Updating encoder config: ', encoderConfig);
            socket.emit(SE_SET_PROFILE_CONFIG, 'enc', false, JSON.stringify({'enc' : encoderConfig,}));
          }
        }
        else if(action.type === SETTINGS_INTERCOM_UPDATE){
          Logger.info('[SocketMiddleware] Updating intercom config: ', action.data);

          socket.emit(SE_SET_PROFILE_CONFIG, 'intercom', false, JSON.stringify({'intercom' : action.data }));
        }
         else if(action.type === SETTINGS_INTERCOM_INPUTS_UPDATE){
          const intercomProfiles = { ...store.getState().config.intercomChannelProfile };
          const intercom= { ...store.getState().config.intercom };
          const maxEntries = store.getState().streamhub.inputAudioDeviceSelected.max_entries;
          action.data.inputs.forEach((value, index)=>{
            intercomProfiles[value.id].audioInputMap=action.data.activeInputsChannels[index];
          })
          for (let index = 1; index <= maxEntries; index++) {
            intercomProfiles[index].aes67SinkSource = action.data.inputAES67SourceValues[index];
          }
          if (action.data.nbInputVisible && intercom.nbInputVisible !== action.data.nbInputVisible){
                intercom.nbInputVisible = action.data.nbInputVisible;
                Logger.info('[SocketMiddleware] Updating intercom profile: ', intercomProfiles);
                socket.emit(SE_SET_OBJECT_CONFIG, 'intercom', JSON.stringify(intercom));
          }
          Logger.info('[SocketMiddleware] Updating intercom channel profile: ', intercomProfiles);
          socket.emit(SE_SET_OBJECT_CONFIG, 'intercomChannelProfile', JSON.stringify(intercomProfiles));
        }
        else if (action.type === SETTINGS_INTERCOM_OUTPUTS_UPDATE){
          const intercomProfiles = { ...store.getState().config.intercomChannelProfile };
          const intercom= { ...store.getState().config.intercom };
          const maxEntries = store.getState().streamhub.outputAudioDeviceSelected.max_entries;
          action.data.inputs.forEach((value, index)=>{
            intercomProfiles[value.id].audioOutputMap=action.data.activeOutputsChannels[index];
          })
          for (let index = 1; index <= maxEntries; index++) {
            intercomProfiles[index].aes67SourceAddress = action.data.SourceAddresses[index];
            intercomProfiles[index].aes67SourceEnabled = action.data.SourceAddressEnable[index];
          }
          if (action.data.nbOutputVisible && intercom.nbOutputVisible !== action.data.nbOutputVisible){
              intercom.nbOutputVisible = action.data.nbOutputVisible;
              Logger.info('[SocketMiddleware] Updating intercom profile: ', intercomProfiles);
              socket.emit(SE_SET_OBJECT_CONFIG, 'intercom', JSON.stringify(intercom));
        }
          Logger.info('[SocketMiddleware] Updating intercom channel profile: ', intercomProfiles);
          socket.emit(SE_SET_OBJECT_CONFIG, 'intercomChannelProfile', JSON.stringify(intercomProfiles));

        }
        else if (action.type === SETTINGS_GET_AES67_SOURCES_LIST){
          Logger.info('[SocketMiddleware] Get AES67 sources list');

          socket.emit(SE_GET_AES67_SOURCES_LIST);
        }
        else if(action.type === SETTINGS_VIDEOCARDS_UPDATE){
          const videoCardsConfig = {
            ...store.getState().config.videoCards,
            [action.id]: action.data
          };
          Logger.info('[SocketMiddleware] Updating video cards config: ', videoCardsConfig);
          socket.emit(SE_SET_PROFILE_CONFIG, 'videoCards', false, JSON.stringify({'videoCards' : videoCardsConfig}));
          Logger.info('[SocketMiddleware] Updating video cards config needs restart');
          socket.emit(SE_RESTART_STREAMHUB);
        }
        else if(action.type === PASSWORD_UPDATE){
          const config = { ...store.getState().config };
          const { type, role, ...otherProps } = action;
          Logger.info('[SocketMiddleware] Password update for: ', role, otherProps);

          // Add StreamHub log for each modification
          if(action.new!==""){
            socket.emit(SE_ADD_LOG, `Password for ${role} updated`);
          }
          if(action.sessionTimeout !== undefined){
            socket.emit(SE_ADD_LOG, `Session timeout for ${role} updated`);
          }
          if(action.hasPrivilege !== config.auth[role].hasPrivilege){
            socket.emit(SE_ADD_LOG, `Session privileges for ${role} updated`);
          }
          if(action.enable !== config.auth[role].enable){
            if(action.enable){
              socket.emit(SE_ADD_LOG, `Session for ${role} enabled`);
            }else{
              socket.emit(SE_ADD_LOG, `Session for ${role} disabled`);
            }
          }

          socket.emit('changePassword', role, otherProps);
        }
        else if(action.type === PASSWORD_SAMBA_UPDATE){
          const { type, role, ...otherProps } = action;
          Logger.info('[SocketMiddleware] Samba password update');

          socket.emit('updateSambaFtpPassword', otherProps);
        }
        else if(action.type === TOOLS_LOGS_EXPORT){
          socket.emit(SE_GET_LOG_FILE);
        }
        else if(action.type === TOOLS_LOGS_PAGE_CHANGE){
          Logger.info('[SocketMiddleware] Logs page change');
          socket.emit(SE_LOGS, action.page, action.perPage, store.getState().tools.logs.filters);
        }
        else if(action.type === TOOLS_LOGS_FILTERS_CHANGE){
          Logger.info('[SocketMiddleware] Logs filters change');
          socket.emit(SE_LOGS, 1, store.getState().tools.logs.perPage, action.filters);
        }
        else if(action.type === TOOLS_SYSTEM_RESTART_STREAMHUB){
          Logger.info('[SocketMiddleware] Restart streamhub');
          socket.emit(SE_RESTART_STREAMHUB);
        }
        else if(action.type === TOOLS_SYSTEM_REBOOT_SERVER){
          Logger.info('[SocketMiddleware] Reboot server');
          socket.emit(SE_REBOOT_SERVER);
        }
        else if(action.type === TOOLS_SYSTEM_HALT_SERVER){
          Logger.info('[SocketMiddleware] Halt server');
          socket.emit(SE_HALT_SERVER);
        }
        else if(action.type === TOOLS_SYSTEM_UPLOAD_FIRMWARE_START){
          Logger.info('[SocketMiddleware] Update system firmware:', action.file);
          const file = action.file;
          if(file){
            socket.emit(SE_UPDATE_FIRMWARE_START, file.name, file.size);
          }
        }
        else if(action.type === TOOLS_SYSTEM_UPLOAD_FIRMWARE_REBOOT){
          Logger.info('[SocketMiddleware] System firmware updated: rebooting...');
          socket.emit(SE_CONFIRM_UPDATE_REBOOT);
        }
        else if(action.type === TOOLS_SYSTEM_CHECK_VIDEOCARD_FIRMWARE){
          Logger.info('[SocketMiddleware] Check Video card firmware');
          socket.emit(SE_UPDATE_VIDEO_CARD);
        }
        else if(action.type === TOOLS_SYSTEM_UPDATE_VIDEOCARD_FIRMWARE){
          Logger.info('[SocketMiddleware] Update Video card firmware');
          socket.emit(SE_UPDATE_VIDEO_CARD_CONFIRM);
        }
        else if(action.type === TOOLS_SYSTEM_GENERATE_REPORT){
          Logger.info('[SocketMiddleware] Generate report');
          const identifier = store.getState().streamhub.name;
          socket.emit(SE_GET_REPORT, identifier);
        }
        else if(action.type === LICENSE_UPLOAD){
          Logger.info('[SocketMiddleware] Update license:', action.file);
          const file = action.file;
          if(file){
            const reader = new FileReader();
            reader.onload = (loadEvent) => {
              socket.emit(SE_INSTALL_LICENSE, loadEvent.target.result, file.name);
            };
            // Load selected file
            reader.readAsDataURL(file);
          }
        }
        else if(action.type === LICENSE_UPLOAD_SMPTE2110){
          Logger.info('[SocketMiddleware] Update license:', action.file);
          const file = action.file;
          if(file){
            const reader = new FileReader();
            reader.onload = (loadEvent) => {
              socket.emit(SE_INSTALL_LICENSE_SMPTE2110, loadEvent.target.result, file.name);
            };
            // Load selected file
            reader.readAsDataURL(file);
          }
        }
        else if (action.type === TIMEOUT_RESET){
          Logger.info('[SocketMiddleware] Timeout reset');
          socket.emit(SE_TIMEOUT_RESET);
        }
        else if (action.type === DASHBOARD_INPUT_REMOTE_CONTROL_TOKEN_REPLACE) {
          Logger.info('[SocketMiddleware] Remote control token replace', {url: action.url, hardwareIdentifier: action.deviceHardwareID});
          socket.emit(SE_REMOTE_CONTROL_TOKEN_REPLACE, {url: action.url, hardwareIdentifier: action.deviceHardwareID});
        }
        else if (action.type === ADD_LOG) {
          const log = action.log;
          Logger.info('[SocketMiddleware] Adding log', log );
          socket.emit(SE_ADD_LOG, log);
        }
      }
    }
  }
}