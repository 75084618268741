import PropTypes from "prop-types";
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Translate} from "react-localize-redux";

import { AWBadgeLabel } from "@aviwest/ui-kit";
import { isEmptyObject } from "../../../../utils/string-utils";

const propTypes = {
  ptp: PropTypes.object,
};

class SystemTools extends Component {

  render() {
    const {
      ptp,
      AES67Allowed
    } = this.props;
    return (
        <div className="tools-section">
          <div className="header">
            <div className="title">
              <Translate id="genericLabel.STATUS.text"/>
            </div>
          </div>
          <div className="content pretty-scroll">
             {!isEmptyObject(ptp) && ptp.smpte2110 &&
              <>
                <div className="title2">
                  SMPTE 2110 PTP
                    <AWBadgeLabel fill color='white' className="lock" id="system_ptp_lockStatus">
                      {`${ptp.smpte2110.LockStatus ? 'Locked' : 'Not locked'}`}
                    </AWBadgeLabel>
                </div>
                <div className="hardware-spec">
                  <ul>
                    <li id="system_ptp_grandMasterId_smpte2110" value={ptp.smpte2110.GmId}><span className="label">Grandmaster ID</span>{ptp.smpte2110.GmId}</li>
                    <li id="system_ptp_boundaryClockId_smpte2110" value={ptp.smpte2110.BcId}><span className="label">Boundary clock ID</span>{ptp.smpte2110.BcId}</li>
                    <li id="system_ptp_boundaryClockMacAddress_smpte2110" value={ptp.smpte2110.BcHwId}><span className="label">Boundary clock MAC adress</span>{ptp.smpte2110.BcHwId}</li>
                  </ul>
                </div>
              </>
            }
            {!isEmptyObject(ptp) && ptp.aes67 && AES67Allowed &&
              <>
                <div className="title2">
                  AES 67 PTP
                    <AWBadgeLabel fill color='white' className="lock" id="system_ptp_lockStatus">
                      {`${ptp.aes67.LockStatus ? 'Locked' : 'Not locked'}`}
                    </AWBadgeLabel>
                </div>
                <div className="hardware-spec">
                  <ul>
                    <li id="system_ptp_grandMasterId_aes67" value={ptp.aes67.GmId}><span className="label">Grandmaster ID</span>{ptp.aes67.GmId}</li>
                  </ul>
                </div>
              </>
            }
            {!isEmptyObject(ptp) && ptp.nmosReg &&
              <>
                <div className="title2">
                  NMOS Registry Status
                    <AWBadgeLabel fill color='white' className="lock" id="system_ptp_lockStatus_nmosReg">
                      {`${ptp.nmosReg.rdsLocked ? 'Locked' : 'Not locked'}`}
                    </AWBadgeLabel>
                </div>
                <div className="hardware-spec">
                  <ul>
                    <li id="system_ptp_ip_nmosReg" value={ptp.nmosReg.rdsIp}><span className="label">Registry IP</span>{ptp.nmosReg.rdsIp}</li>
                    <li id="system_ptp_port_nmosReg" value={ptp.nmosReg.rdsPort}><span className="label">Registry Port</span>{ptp.nmosReg.rdsPort}</li>
                  </ul>
                </div>
              </>
            }
          </div>
        </div>
    );
  }
}

SystemTools.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    ptp: state.streamhub.ptp,
    AES67Allowed: state.license.details ? state.license.details.AES67 : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemTools);