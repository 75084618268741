import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import {Redirect, Route, Switch, withRouter} from "react-router";
import {Link} from "react-router-dom";
import {Translate} from "react-localize-redux";

import LogsTools from './logs';
import SystemTools from './system';
import StatusTools from './status';
import { isEmptyObject } from '../../../utils/string-utils';

const propTypes = {
  ptp: PropTypes.object,
};
class ToolsPage extends Component {

  constructor(props){
    super(props);

    this.matchingUrl = this.matchingUrl.bind(this);
  }

  matchingUrl(pattern){
    return this.props.location.pathname.indexOf(pattern) !== -1;
  }

  render() {
    const { ptp } = this.props;

    return (
        <div className="tools">
          <Container>
            <Row>
              <Col className="at-least-medium"
                   xs={{ offset: 0, size: 0 }} md={{ offset: 0, size: 3 }}>
                <Nav className="aw-nav"
                     pills
                     vertical>
                  <NavItem>
                    <NavLink id="tools_navLink_logs" className="aw-nav-link" tag={Link} to="/tools/logs" active={this.matchingUrl('/logs')}>
                      <Translate id="genericLabel.LOGS.text"/>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink id="tools_navLink_system" className="aw-nav-link" tag={Link} to="/tools/system" active={this.matchingUrl('/system')}>
                      <Translate id="genericLabel.SYSTEM.text"/>
                    </NavLink>
                  </NavItem>
                  {!isEmptyObject(ptp) &&
                    <NavItem>
                      <NavLink className="aw-nav-link" tag={Link} to="/tools/status" active={this.matchingUrl('/status')}>
                        <Translate id="genericLabel.STATUS.text"/>
                      </NavLink>
                    </NavItem>
                  }
                  {/*<NavItem>*/}
                    {/*<NavLink className="aw-nav-link" tag={Link} to="/tools/intercom" active={this.matchingUrl('/intercom')}>*/}
                      {/*<Translate id="genericLabel.INTERCOM.text"/>*/}
                    {/*</NavLink>*/}
                  {/*</NavItem>*/}
                  {/*<NavItem>*/}
                    {/*<NavLink className="aw-nav-link" tag={Link} to="/tools/advanced" active={this.matchingUrl('/advanced')}>*/}
                      {/*<Translate id="genericLabel.ADVANCED.text"/>*/}
                    {/*</NavLink>*/}
                  {/*</NavItem>*/}
                </Nav>
              </Col>
              <Col>
                <Switch>
                  <Route exact path="/tools/logs" component={LogsTools}/>
                  <Route exact path="/tools/system" component={SystemTools}/>
                  <Route exact path="/tools/status" component={StatusTools}/>
                  {/*<Route exact path="/tools/intercom" component={IntercomTools}/>*/}
                  {/*<Route exact path="/tools/advanced" component={AdvancedTools}/>*/}
                  <Redirect from="/tools*" to="/tools/logs"/>
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
    );
  }
}

ToolsPage.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    ptp: state.streamhub.ptp,
  };
};

export default withRouter(connect(mapStateToProps)(ToolsPage));