import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from "react-redux";

import LicenseForm from './license-form';

import {withLocalize} from "react-localize-redux";
import {openLicenseModal, uploadLicense} from "../../../misc/license.actions";
import {licenseFeaturesPropTypes} from "../../../utils/models-prop-types";
import {LICENSE_STATUS_INVALID, LICENSE_STATUS_VALID} from "../../../constants";
import LicenseDetails from "./license-details";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  callUploadLicense: PropTypes.func.isRequired,
  features: licenseFeaturesPropTypes,
  licenseStatus: PropTypes.oneOf([LICENSE_STATUS_VALID, LICENSE_STATUS_INVALID]),
  translate: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  orionConnectionStatus: PropTypes.bool.isRequired,
  hotdeployInProgress: PropTypes.bool.isRequired,
  uploading: PropTypes.bool,
  result: PropTypes.shape({
    res: PropTypes.number.isRequired,
    message: PropTypes.string,
  }),
  message: PropTypes.string
};

const LicenseModal = (props) => {
  const { hardwareIdentifier, callUploadLicense, callCloseModal, details, features, licenseStatus, opened, orionConnectionStatus, hotdeployInProgress, translate, uploading, result, system, message } = props;
  return (
  <AWModal id="license_modal"
           icon="license"
           open={opened}
           onClose={ licenseStatus === LICENSE_STATUS_VALID ? callCloseModal : undefined }
           title={ translate("genericLabel.LICENSE.text") }>
      { opened &&
        <Fragment>
          <LicenseDetails hardwareIdentifier={hardwareIdentifier}
                          details={details}
                          features={features}
                          status={licenseStatus}
                          orionConnectionStatus={orionConnectionStatus}
                          hotdeployInProgress={hotdeployInProgress}
                          message={message}
                          isPAYG={system.isPAYG}/>
          {!system.isDocker && !system.isPAYG &&
              <LicenseForm loading={uploading}
                       result={result}
                       onSubmit={callUploadLicense}/>
          }
        </Fragment>
      }
    </AWModal>
  );
};

LicenseModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    hardwareIdentifier: state.streamhub.hardwareIdentifier,
    details: state.license.details,
    features: state.license.features,
    licenseStatus: state.license.status,
    opened: state.license.opened,
    orionConnectionStatus: state.license.orionConnectionStatus,
    hotdeployInProgress: state.license.hotdeployInProgress,
    uploading: state.license.uploading,
    result: state.license.result,
    system: state.streamhub.system,
    message: state.license.message
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(openLicenseModal(false)),
    callUploadLicense: (file) => dispatch(uploadLicense(file))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LicenseModal));